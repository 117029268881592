import React, { FC, useState, useEffect, useContext } from 'react';
import { Environment, Errors, RedirectData } from '../../../api/models/Data.model';
import { Button, JamfIcon, TextInput } from '@jamf/design-system-react';
import { Trans, useTranslation } from 'react-i18next';
import { EnvContext } from '../../../context/EnvContext';

interface EnvironmentListProps {
    environments: Environment[];
    errors: Errors[];
    redirectData: RedirectData;
}

const EnvironmentList: FC<EnvironmentListProps> = ({ environments = [], errors = [], redirectData }) => {
    const { t } = useTranslation();
    const { supportEmail } = useContext(EnvContext);

    const [list, setList] = useState<Environment[]>(environments);
    const [errorList, setErrorList] = useState<Errors[]>(errors);
    const [redirect, setRedirect] = useState<RedirectData>(redirectData);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setList(
            environments.filter(
                (env) =>
                    env.name.toLowerCase().includes(search.toLowerCase()) ||
                    env.url.toLowerCase().includes(search.toLowerCase())
            )
        );

        setErrorList(errors);
        setRedirect(redirect);
    }, [search, environments, errors, redirect]);

    const hasUrl = redirectData.url !== undefined && redirectData.url !== null && redirectData.url !== '';
    const returnUrl = hasUrl ? '&returnUrl=' + redirectData.url : '';

    return (
        <>
            {environments.length > 10 && (
                <TextInput
                    type="text"
                    name="search"
                    leadingIcon="magnifier"
                    value={search}
                    onChange={(search) => setSearch(search)}
                    className="width"
                ></TextInput>
            )}
            {errorList.length > 0 &&
                !search &&
                errorList.map((envErr, envIndex) => {
                    const name = envErr.name;

                    return (
                        <div className="profile-link-section" key={envIndex}>
                            <div className="section-content">
                                <div className="alert danger" key={envIndex}>
                                    <JamfIcon width={24} name="error" />
                                    <div className="flex-column">
                                        <span className="title">
                                            <Trans i18nKey={'list.error-title'}>{{ name }}</Trans>
                                        </span>
                                        <span>
                                            <Trans i18nKey={'list.error-hint'}>{{ supportEmail }}</Trans>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            {list.map((env, index) => {
                return (
                    <div className="profile-link-section" key={index}>
                        <div className="section-content">
                            <span className="env-list-name">{env.name}</span>
                            <span className="env-list-url">{env.url.replace('https://', '')}</span>
                        </div>
                        <Button
                            styleType="ghost"
                            className="link"
                            label={t('client-list.cta')}
                            onClick={() => (window.location.href = env.url + '?redirect=true' + returnUrl)}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default EnvironmentList;
