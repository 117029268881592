import React, { FC, MutableRefObject, ReactElement, SyntheticEvent, useContext, useRef, useState } from 'react';
import zxcvbn from 'zxcvbn';
import ProfileApi from '../../../api/ProfileApi';
import { AuthContext } from '../../../context/AuthContext';
import ROUTES from '../../../utilities/routes';
import ContentHeader from '../../Content/ContentHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, JamfIcon, ProgressBar, TextInput } from '@jamf/design-system-react';
import PASSWORD_STATUS from '../../../utilities/passwordStatus';
import classNames from 'classnames';
import { ApiError } from '../../../api/ApiError';

const Password: FC = (): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(AuthContext);

    const [state, setState] = useState({
        error: '',
        password: '',
        passwordNew: '',
        passwordNewConfirmation: '',
        passwordStrength: 0,
        passwordWarning: '',
        success: false,
    });

    const { error, password, passwordNew, passwordNewConfirmation, passwordStrength, success, passwordWarning } = state;

    const passwordInput: MutableRefObject<any> = useRef<HTMLInputElement>();
    const passwordNewInput: MutableRefObject<any> = useRef<HTMLInputElement>();
    const passwordConfirmInput: MutableRefObject<any> = useRef<HTMLInputElement>();

    const resetPassword = async (): Promise<void> => {
        const { password, passwordNew, passwordStrength } = state;

        if (!password) {
            passwordInput.current.focus();
            return;
        }

        if (!passwordNew) {
            passwordNewInput.current.focus();
            return;
        }

        if (!passwordNewConfirmation) {
            passwordConfirmInput.current.focus();
            return;
        }

        if (passwordNew !== passwordNewConfirmation) {
            setState({ ...state, error: 'password-no-match' });
            passwordConfirmInput.current.focus();
            return;
        }

        if (password && passwordStrength < 2) {
            setState({ ...state, error: passwordWarning ? 'common-password' : 'weak-password' });
            passwordNewInput.current.focus();
            return;
        }

        if (passwordWarning && passwordStrength < 3) {
            setState({ ...state, error: 'common-password' });
            passwordNewInput.current.focus();
            return;
        }

        setIsLoading(true);

        try {
            await ProfileApi.changePassword({ password, passwordNew, passwordNewConfirmation });

            // Refer the user to a confirmation
            setState((prevState) => ({
                ...prevState,
                success: true,
                error: '',
                password: '',
                passwordNew: '',
                passwordNewConfirmation: '',
            }));

            setIsLoading(false);
        } catch (error: any) {
            if (error instanceof ApiError) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }

                const translatableErrors = [
                    'password-no-match',
                    'password-no-match-current',
                    'password-not-complex',
                    'password-cannot-be-reused',
                ];
                // Set the error state and reset the password
                setState((prevState) => ({
                    ...prevState,
                    error: translatableErrors.includes(error.message.error)
                        ? error.message.error // We have a localized string for this error, show it.
                        : '', // This is an error we don't have a localized string for, so we suppress it
                    password:
                        error.message.error === 'password-no-match' ||
                        error.message.error === 'password-no-match-current'
                            ? ''
                            : prevState.password,
                    passwordNew:
                        error.message.error === 'password-not-complex' ||
                        error.message.error === 'password-cannot-be-reused'
                            ? prevState.passwordNew
                            : '',
                    passwordWarning: translatableErrors.includes(error.message.error)
                        ? error.message.error // We have a localized string for this error, show it.
                        : prevState.passwordWarning,
                    passwordNewConfirmation: '',
                }));
                passwordInput.current.focus();
                setIsLoading(false);
            }
        }
    };

    const handlePasswordChange = (passwordNew: string): void => {
        const { firstName, email } = user;

        if (email && firstName) {
            const {
                score,
                feedback: { warning },
            } = zxcvbn(passwordNew, [email, firstName]);
            setState({
                ...state,
                passwordStrength: score,
                passwordWarning: warning,
                error:
                    state.error === 'weak-password' ||
                    state.error === 'common-password' ||
                    state.error === 'password-not-complex' ||
                    state.error === 'password-cannot-be-reused'
                        ? ''
                        : state.error,
                passwordNew: passwordNew,
                success: false,
            });
        } else return;
    };

    const handleSubmit = (event: SyntheticEvent): void => {
        event.preventDefault();
        resetPassword();
    };

    return (
        <div>
            <ContentHeader heading={t('profile.change-password')} returnTo={ROUTES.PROFILE.ROOT} />
            <Card className="card-wide">
                <CardBody className="card-body">
                    <form>
                        <TextInput
                            id="current-password"
                            className="margin-bottom width"
                            type="password"
                            leadingIcon="lock"
                            label={t('password.current')}
                            value={password}
                            onChange={(password): void => setState({ ...state, password, error: '', success: false })}
                            forwardRef={passwordInput}
                            descenderText={t(`errors.${error}`)}
                            hasError={error === 'password-no-match-current'}
                        />
                        <TextInput
                            id="new-password"
                            className={classNames({ 'margin-bottom': !passwordNew }, 'width')}
                            type="password"
                            leadingIcon="lock"
                            label={t('password.new')}
                            value={passwordNew}
                            onChange={(passwordNew): void => handlePasswordChange(passwordNew)}
                            forwardRef={passwordNewInput}
                            hasError={
                                error === 'weak-password' ||
                                error === 'common-password' ||
                                error === 'password-not-complex' ||
                                error === 'password-no-match' ||
                                error === 'password-cannot-be-reused'
                            }
                        />
                        {passwordNew && (
                            <ProgressBar
                                className="progress-bar margin-bottom-half"
                                barColor={passwordWarning ? 'danger' : PASSWORD_STATUS[passwordStrength].color}
                                labelColor={passwordWarning ? 'danger' : PASSWORD_STATUS[passwordStrength].color}
                                percentComplete={PASSWORD_STATUS[passwordStrength].percent}
                                label={t(
                                    [
                                        'weak-password',
                                        'common-password',
                                        'password-not-complex',
                                        'password-cannot-be-reused',
                                    ].includes(error)
                                        ? `errors.${error}` // reference to a localized string
                                        : `password.strength.${
                                              // localized password strength indicator
                                              passwordWarning ? 'common' : PASSWORD_STATUS[passwordStrength].t
                                          }`
                                )}
                            ></ProgressBar>
                        )}
                        <TextInput
                            id="confirm-password"
                            label={t('global.confirm-password')}
                            value={passwordNewConfirmation}
                            onChange={(passwordConfirmation): void =>
                                setState({ ...state, passwordNewConfirmation: passwordConfirmation, error: '' })
                            }
                            hasError={!!error && error === 'password-no-match'}
                            descenderText={t(`errors.${error}`)}
                            type="password"
                            leadingIcon="lock"
                            className="margin-bottom margin-top width"
                            forwardRef={passwordConfirmInput}
                        ></TextInput>

                        <div className="card-buttons">
                            {success && (
                                <div className="align-items-center change-indicator">
                                    <JamfIcon
                                        name="checkCircle"
                                        width={18}
                                        className="margin-right-half text-success"
                                    ></JamfIcon>
                                    <span>{t('password.changed')}</span>
                                </div>
                            )}
                            <Button
                                styleType="secondary"
                                label={t('global.back')}
                                onClick={(): void => history.push(ROUTES.PROFILE.ROOT)}
                            />
                            <Button
                                isSubmit={true}
                                styleType="primary"
                                onClick={handleSubmit}
                                label={t('global.save')}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                            />
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default Password;
