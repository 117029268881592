import React, { Component, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import ProfileApi from '../../api/ProfileApi';
import { AuthContext } from '../../context/AuthContext';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CardHeader, CardTitle, CardBody, SpinnerIcon, Button, JamfIcon } from '@jamf/design-system-react';

class RegisterVerify extends Component<WithTranslation & RouteComponentProps> {
    state = {
        email: '',
        token: '',
        isLoading: false,
        success: false,
    };

    static contextType = AuthContext;

    async componentDidMount(): Promise<void> {
        const { location } = this.props;

        if (location.search !== undefined) {
            const params = queryString.parse(location.search);

            // Need to use this workaround for checking object keys
            // See also: https://github.com/hapijs/hapi/issues/3280
            if (
                Object.prototype.hasOwnProperty.call(params, 'email') &&
                Object.prototype.hasOwnProperty.call(params, 'token')
            ) {
                this.setState({
                    email: params.email,
                    token: params.token,
                });
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any): void {
        const { email, token } = this.state;
        if (prevState.email !== email || prevState.token !== token) {
            if (email === '' && token === '') {
                return;
            }

            this.verifyUser();
        }
    }

    verifyUser = async (): Promise<void> => {
        const { email, token } = this.state;
        const { history } = this.props;

        this.setState({
            isLoading: true,
        });

        await ProfileApi.verifyProfile({ email, token });

        await this.context.getProfile();

        this.setState(
            {
                isLoading: false,
                success: true,
            },
            () => {
                history.push(ROUTES.LOGIN.SETUP_TWOFACTOR_APP);
            }
        );
    };

    goToLogin = (): void => {
        this.props.history.push(ROUTES.LOGIN.ROOT);
    };

    goToProfile = (): void => {
        this.props.history.push(ROUTES.PROFILE.ROOT);
    };

    render(): ReactElement {
        const { isLoading, success } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <AuthCard>
                    <CardHeader className="card-header justify-flex-start">
                        <Button leadingIcon styleType="secondary" className="back-button" isDisabled={true}>
                            <JamfIcon name="arrow" width="24" height="24" />
                        </Button>
                        <CardTitle className="card-title">{t('register.title')}</CardTitle>
                    </CardHeader>
                    <CardBody className="card-body">
                        <div className="align-items-center">
                            <SpinnerIcon
                                className="margin-right-half"
                                id="spinner"
                                size="18"
                                color={'var(--color-primary-base)'}
                            ></SpinnerIcon>
                            <span>{t('global.verifying')}...</span>
                        </div>
                    </CardBody>
                </AuthCard>
            );
        }

        if (success) {
            return (
                <AuthCard>
                    <CardHeader className="card-header justify-flex-start">
                        <Button leadingIcon styleType="secondary" className="back-button" onClick={this.goToLogin}>
                            <JamfIcon name="arrow" width="24" height="24" />
                        </Button>
                        <CardTitle className="card-title">{t('register.title')}</CardTitle>
                    </CardHeader>
                    <CardBody className="card-body">
                        <div className="align-items-center">
                            <JamfIcon
                                name="checkCircle"
                                width={18}
                                className="margin-right-half text-success"
                            ></JamfIcon>
                            <span>{t('register.complete')}</span>
                        </div>
                        <div className="input-row justify-flex-end margin-top">
                            <Button
                                label={this.context.isAuthenticated ? t('global.continue') : t('login.cta')}
                                onClick={this.context.isAuthenticated ? this.goToProfile : this.goToLogin}
                            ></Button>
                        </div>
                    </CardBody>
                </AuthCard>
            );
        }

        return (
            <AuthCard>
                <CardHeader className="card-header justify-flex-start">
                    <Button leadingIcon styleType="secondary" className="back-button" onClick={this.goToLogin}>
                        <JamfIcon name="arrow" width="24" height="24" />
                    </Button>
                    <CardTitle className="card-title">{t('register.title')}</CardTitle>
                </CardHeader>
                <CardBody className="card-body">
                    <p>{t('register.request.description')}</p>
                    <div className="input-row justify-flex-end margin-top-half">
                        <Button label={t('register.request.return')} onClick={this.goToLogin}></Button>
                    </div>
                </CardBody>
            </AuthCard>
        );
    }
}

export default withRouter(withTranslation()(RegisterVerify));
