import React, { FC, useContext, useEffect, useState } from 'react';
import AuthCard from './AuthCard/AuthCard';
import { Button, CardBody, CardHeader, CardTitle, JamfIcon, SpinnerIcon } from '@jamf/design-system-react';
import { Trans, useTranslation } from 'react-i18next';
import EnvironmentList from './EnvironmentList/EnvironmentList';
import { Environment, Environments, Errors, RedirectData } from '../../api/models/Data.model';
import ProfileApi from '../../api/ProfileApi';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../utilities/routes';
import AuthenticationApi from '../../api/AuthenticationApi';
import { EnvContext } from '../../context/EnvContext';
import { ApiError } from '../../api/ApiError';

const ClientList: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { supportEmail } = useContext(EnvContext);

    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [environmentErrors, setEnvironmentErrors] = useState<Errors[]>([]);
    const [redirectData, setRedirectData] = useState<RedirectData>({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const getEnvironments = async (): Promise<void> => {
        try {
            const env: Environments = await ProfileApi.getEnvironments();
            setEnvironmentErrors(env.errors);
            setEnvironments(env.environments);
            setRedirectData(env.redirectData);
        } catch (error) {
            if (error instanceof ApiError) {
                setError(error.message.error);
            }
        }
        setIsLoading(false);
    };

    const goToProfile = () => {
        history.push(ROUTES.PROFILE.ROOT);
    };

    const goToInvitations = () => {
        history.push(ROUTES.LOGIN.CLIENT_INVITATIONS);
    };

    const logout = async () => {
        await AuthenticationApi.logout();
        history.push(ROUTES.LOGIN.ROOT);
    };

    useEffect(() => {
        getEnvironments();
    }, []);

    return (
        <AuthCard>
            <CardHeader className="card-header">
                <CardTitle className="card-title">{t('client-list.title')}</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
                {isLoading && <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />}
                {!isLoading && error && (
                    <div className="alert danger">
                        <JamfIcon width={24} name="error" />
                        <div className="flex-column">
                            <span className="title">{t('list.error-title-generic')}</span>
                            <span>
                                <Trans i18nKey={'list.error-hint'}>{{ supportEmail }}</Trans>
                            </span>
                        </div>
                    </div>
                )}
                {!isLoading && (environments.length >= 0 || environmentErrors.length > 0) && (
                    <EnvironmentList
                        environments={environments}
                        errors={environmentErrors}
                        redirectData={redirectData}
                    />
                )}
                {!isLoading && !error && environmentErrors.length === 0 && environments.length === 0 && (
                    <div className="flex-column">
                        <span>{t('client-list.no-accounts')}</span>
                    </div>
                )}
                <div className="input-row margin-top-large">
                    <Button leadingIcon styleType="secondary" label={t('list.profile-button')} onClick={goToProfile}>
                        <JamfIcon name="user" width="24" height="24" />
                    </Button>
                    <Button
                        leadingIcon
                        styleType="secondary"
                        label={t('list.invitation-button')}
                        onClick={goToInvitations}
                    >
                        <JamfIcon name="inbox" width="24" height="24" />
                    </Button>
                    <Button leadingIcon styleType="secondary" label={t('logout.cta')} onClick={logout}>
                        <JamfIcon name="logout" width="24" height="24" />
                    </Button>
                </div>
            </CardBody>
        </AuthCard>
    );
};

export default ClientList;
