import React, { Component, createRef, MutableRefObject, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';
import ProfileApi from '../../api/ProfileApi';
import ROUTES from '../../utilities/routes';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, JamfIcon, ProgressBar, TextInput } from '@jamf/design-system-react';
import PASSWORD_STATUS from '../../utilities/passwordStatus';
import { EnvContext } from '../../context/EnvContext';
import { ApiError } from '../../api/ApiError';

interface AccountRegistrationData {
    alreadyHaveAnAccount: Function;
    email: string;
}

class Register extends Component<WithTranslation & RouteComponentProps & AccountRegistrationData> {
    state = {
        email: '',
        error: '',
        emailError: '',
        isLoading: false,
        password: '',
        passwordConfirmation: '',
        passwordStrength: 0,
        passwordWarning: '',
        token: '',
        alreadyHaveAnAccount: false,
    };

    emailInput: MutableRefObject<any> = createRef();
    passwordInput: MutableRefObject<any> = createRef();
    passwordConfirmInput: MutableRefObject<any> = createRef();

    static contextType = EnvContext;

    componentDidMount(): void {
        this.autoFocus();
        this.setState({ email: this.props.email });
    }

    handlePasswordChange = (password: string): void => {
        const {
            score,
            feedback: { warning },
        } = zxcvbn(password, [this.state.email]);
        this.setState({
            passwordStrength: score,
            passwordWarning: warning,
            password,
            error: '',
        });
    };

    handleSubmit = async (event: any): Promise<void> => {
        const { email, password, passwordConfirmation, passwordStrength, passwordWarning } = this.state;
        const { history } = this.props;

        this.setState({ emailError: '' });

        event.preventDefault();

        // Do nothing if the required fields are blank
        if (!password || !passwordConfirmation) {
            this.autoFocus();
            return;
        }

        if (password !== passwordConfirmation) {
            this.setState({ error: 'password-no-match' });
            this.passwordConfirmInput.current.focus();
            return;
        }

        if (password && passwordStrength < 2) {
            this.setState({ error: passwordWarning ? 'common-password' : 'weak-password' });
            this.passwordInput.current.focus();
            return;
        }

        if (passwordWarning && passwordStrength < 3) {
            this.setState({ error: 'common-password' });
            this.passwordInput.current.focus();
            return;
        }

        this.setState({
            isLoading: true,
        });

        const invitationResponse = await ProfileApi.getInvitationEmail().catch(() => undefined);
        if (!invitationResponse || invitationResponse.error || invitationResponse.email !== email) {
            this.setState({ emailError: 'no-session-for-invite', isLoading: false });
            return;
        }

        try {
            const clientId = localStorage.getItem('clientId') ?? '';

            // Create the profile...
            await ProfileApi.createProfile({ email, password, clientId });

            // Remove the Client ID from the Local Storage
            localStorage.removeItem('clientId');

            this.setState(
                {
                    isLoading: false,
                },
                () => history.push(ROUTES.REGISTER.VERIFY)
            );
        } catch (error) {
            if (error instanceof ApiError) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }

                let errorMessage = 'something-went-wrong';

                if (error.message.error === 'password-not-complex') {
                    errorMessage = 'password-not-complex';
                }

                if (error.message.error === 'password-cannot-be-reused') {
                    errorMessage = 'password-cannot-be-reused';
                }

                if (error.message.errors !== undefined && error.message.errors.email) {
                    errorMessage = 'email-taken';
                }

                // Set the error state and reset the password
                this.setState({
                    error: errorMessage,
                    isLoading: false,
                    password: '',
                    passwordConfirmation: '',
                });

                this.autoFocus();
            }
        }
    };

    goBack = (): void => {
        this.props.history.push(`${ROUTES.LOGIN.ROOT}?invite=1`);
    };

    autoFocus = (): void => {
        if (this.passwordInput.current) {
            if (this.passwordInput.current.value === '') {
                this.passwordInput.current.focus();
                return;
            }
        }

        if (this.passwordConfirmInput.current) {
            if (this.passwordConfirmInput.current.value === '') {
                this.passwordConfirmInput.current.focus();
                return;
            }
        }
    };

    render(): ReactElement {
        const {
            email,
            error,
            emailError,
            isLoading,
            password,
            passwordConfirmation,
            passwordStrength,
            passwordWarning,
        } = this.state;
        const { t } = this.props;

        return (
            <>
                <form>
                    <p>{t('register.perks')}</p>
                    <p>{t('register.description-password')}</p>
                    <div className={classNames('text-label margin-bottom-half', { error: emailError })}>
                        {t('global.emailaddress')}
                    </div>
                    <div className="margin-bottom-large">
                        <div className="register-email-address">
                            <JamfIcon name="envelope" className="margin-right" height={16} width={16} />
                            <span>{email}</span>
                        </div>
                        {emailError && (
                            <div className="descender-container margin-bottom-large">
                                <span className="error-text">{t(`errors.${emailError}`)}</span>
                            </div>
                        )}
                    </div>

                    <TextInput
                        id="password"
                        label={t('global.password')}
                        value={password}
                        onChange={(password) => this.handlePasswordChange(password)}
                        leadingIcon="lock"
                        className={classNames({ 'margin-bottom width': !password }, 'width')}
                        type="password"
                        forwardRef={this.passwordInput}
                        hasError={!!error}
                    ></TextInput>
                    {password && (
                        <ProgressBar
                            className="progress-bar margin-bottom-half"
                            barColor={PASSWORD_STATUS[passwordStrength].color}
                            labelColor={passwordWarning ? 'danger' : PASSWORD_STATUS[passwordStrength].color}
                            percentComplete={PASSWORD_STATUS[passwordStrength].percent}
                            label={t(
                                error === 'weak-password'
                                    ? 'errors.weak-password'
                                    : error === 'common-password'
                                    ? 'errors.common-password'
                                    : `password.strength.${
                                          passwordWarning ? 'common' : PASSWORD_STATUS[passwordStrength].t
                                      }`
                            )}
                        ></ProgressBar>
                    )}
                    <TextInput
                        id="confirm-password"
                        label={t('global.confirm-password')}
                        value={passwordConfirmation}
                        onChange={(passwordConfirmation) => this.setState({ passwordConfirmation, error: '' })}
                        hasError={!!error && error !== 'weak-password' && error !== 'common-password'}
                        descenderText={t(`errors.${error}`)}
                        type="password"
                        leadingIcon="lock"
                        className="margin-bottom margin-top width"
                        forwardRef={this.passwordConfirmInput}
                    ></TextInput>
                    <a
                        href={`mailto:${this.context.supportEmail}`}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('register.contactus')}
                    </a>
                    <div className="input-row">
                        <span
                            className="sign-in-button"
                            onClick={() => {
                                this.props.alreadyHaveAnAccount();
                            }}
                        >
                            {t('register.account-already-created')}
                        </span>
                        <Button
                            isSubmit={true}
                            onClick={(event: any) => this.handleSubmit(event)}
                            label={t('global.continue')}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                            className="margin-top-half"
                        ></Button>
                    </div>
                </form>
            </>
        );
    }
}

export default withRouter(withTranslation()(Register));
