import React from 'react';

export interface DefaultAvatarProps {
    fullName?: string;
    size?: 'small' | 'large';
}

const DefaultAvatar: React.FC<DefaultAvatarProps> = ({ fullName, size = 'small' }) => {
    const getInitials: Function = (fullUserName: string): string => {
        const parts: string[] = fullUserName.split(' ');
        const getFirstChar: Function = (s: string) => s.charAt(0);

        return parts.length === 1
            ? getFirstChar(parts[0])
            : getFirstChar(parts[0]) + getFirstChar(parts[parts.length - 1]);
    };

    return <span className={`avatar-default ${size}`}>{fullName ? getInitials(fullName).toUpperCase() : ''}</span>;
};

export default DefaultAvatar;
