import React, { Component, createRef, MutableRefObject, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AuthenticationApi from '../../api/AuthenticationApi';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CardHeader, CardTitle, CardBody, Button, TextInput, JamfIcon } from '@jamf/design-system-react';
import { ApiError } from '../../api/ApiError';

class ResendVerificationEmail extends Component<WithTranslation & RouteComponentProps<any, any, { email: string }>> {
    state = {
        email: '',
        error: '',
        isLoading: false,
    };

    emailInput: MutableRefObject<any> = createRef();

    componentDidMount(): void {
        // Focus on the input
        this.autoFocus();

        const { state } = this.props.location;

        // Set e-mail address if it's in the location state
        if (state) {
            this.setState({
                email: state.email,
            });
        }
    }

    handleSubmit = (event: any): void => {
        event.preventDefault();
        this.resendVerificationEmail();
    };

    resendVerificationEmail = async (): Promise<void> => {
        const { email } = this.state;
        const { history } = this.props;

        // Do nothing if the email is blank. If so, set the focus.
        if (!email) {
            this.autoFocus();

            return;
        }

        this.setState({
            isLoading: true,
        });

        try {
            // Resending the invitation email
            const response = await AuthenticationApi.resendInvitationEmail({ email });

            this.setState({
                isLoading: false,
            });

            if (response.success) {
                // Refer the user to a confirmation
                history.push(ROUTES.RESENDINVITATIONEMAIL.CONFIRM);
            } else {
                this.setState({
                    error: 'invalid-email-address',
                    isLoading: false,
                });
            }
        } catch (error) {
            if (error instanceof ApiError) {
                if (error.message.message) {
                    this.setState({
                        error: 'invalid-email-address',
                        isLoading: false,
                    });
                }

                this.autoFocus();
            }
        }
    };

    autoFocus = (): void => {
        if (this.emailInput.current) {
            this.emailInput.current.focus();
        }
    };

    goBack = (): void => {
        this.props.history.push({ pathname: `${ROUTES.LOGIN.ROOT}`, search: this.props.location.search });
    };

    render(): ReactElement {
        const { email, error, isLoading } = this.state;
        const { t } = this.props;

        return (
            <AuthCard>
                <CardHeader className="card-header justify-flex-start">
                    <Button leadingIcon styleType="secondary" className="back-button" onClick={this.goBack}>
                        <JamfIcon name="arrow" width="24" height="24" />
                    </Button>
                    <CardTitle className="card-title">{t('resend-verification-email.title')}</CardTitle>
                </CardHeader>
                <CardBody className="card-body">
                    <form>
                        <TextInput
                            leadingIcon="envelope"
                            value={email}
                            onChange={(email) => this.setState({ email, error: '' })}
                            forwardRef={this.emailInput}
                            label={t('global.emailaddress')}
                            hasError={!!error}
                            descenderText={t(`errors.${error}`)}
                            className="margin-bottom width"
                        ></TextInput>
                        <div className="input-row justify-flex-end">
                            <Button
                                isSubmit={true}
                                onClick={this.handleSubmit}
                                label={t('global.submit')}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                                className="margin-top-half"
                            ></Button>
                        </div>
                    </form>
                </CardBody>
            </AuthCard>
        );
    }
}

export default withRouter(withTranslation()(ResendVerificationEmail));
