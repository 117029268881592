import React, { ReactElement } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Image } from '@jamf/design-system-react';
import DefaultAvatar from '../../DefaultAvatar/DefaultAvatar';

const UserAvatar: React.FC = (): ReactElement => {
    let user = {
        avatar: '',
        fullName: '',
        email: '',
    };

    return (
        <AuthContext.Consumer>
            {(value): ReactElement => {
                if ('user' in value) {
                    user = { ...user, ...value.user };
                }
                return (
                    <div className="user-avatar">
                        {user.avatar && user.avatar.length > 0 ? (
                            <Image src={user.avatar} altText={user.fullName} height="36px" width="36px" />
                        ) : (
                            <DefaultAvatar fullName={user.fullName}></DefaultAvatar>
                        )}
                    </div>
                );
            }}
        </AuthContext.Consumer>
    );
};

export default UserAvatar;
