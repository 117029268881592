import React, { FC, useState, ChangeEvent } from 'react';
import ProfileApi from '../../../api/ProfileApi';
import DefaultAvatar from '../../DefaultAvatar/DefaultAvatar';
import { JamfIcon } from '@jamf/design-system-react';

interface ProfileImageProps {
    avatar?: string;
    fullName?: string;
    getProfile: Function;
}

const ProfileImage: FC<ProfileImageProps> = ({ ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const uploadAvatar = async (image: string): Promise<void> => {
        const { getProfile } = props;

        try {
            await ProfileApi.uploadAvatar({ image });

            // Get the new avatar by updating the profile
            await getProfile();
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.log(error);
            }
        }

        setIsLoading(false);
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { files } = event.target;

        if (!files || files.length === 0 || isLoading) {
            return;
        }

        setIsLoading(true);

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>): Promise<void> | null =>
            e.target && e.target.result !== null ? uploadAvatar(e.target.result as string) : null;
        return reader.readAsDataURL(files[0]);
    };

    const { avatar, fullName } = props;

    return (
        <form>
            <label className={`profile-image ${isLoading ? `is-loading` : ``}`} htmlFor="file">
                {avatar ? (
                    <img src={avatar} alt={fullName} className="img-fluid" />
                ) : (
                    <DefaultAvatar fullName={fullName} size={'large'} />
                )}
                <div className="profile-image-overlay">
                    <JamfIcon name="camera" width={24} />
                </div>
            </label>
            <input
                alt="Avatar upload"
                type="file"
                id="file"
                accept="image/png, image/jpeg"
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </form>
    );
};

export default ProfileImage;
