import React, { FC, MutableRefObject, ReactElement, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileApi from '../../../api/ProfileApi';
import { Button, Card, CardBody, JamfIcon, SpinnerIcon, TextInput } from '@jamf/design-system-react';
import classNames from 'classnames';
import { ApiError } from '../../../api/ApiError';

interface TwoFactorPhoneEnableProps {
    phoneNumber: string;
    getPhoneSettings: () => Promise<void>;
}

const TwoFactorPhoneEnable: FC<TwoFactorPhoneEnableProps> = ({ phoneNumber, getPhoneSettings }): ReactElement => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        code: '',
        codeSent: false,
        error: '',
        isEnabling: false,
        isVerifying: false,
        phone: '',
    });

    const phoneInput: MutableRefObject<any> = useRef();
    const codeInput: MutableRefObject<any> = useRef();

    useEffect(() => {
        if (phoneNumber) {
            setState((prevState) => ({ ...prevState, phone: phoneNumber }));
        }
    }, [phoneNumber]);

    const validate = (): boolean => {
        const { phone } = state;
        const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

        // Validation is false by default
        let valid = false;

        // Do nothing if the phone field is blank, instead re-focus
        if (!phone) {
            phoneInput.current.focus();
            return valid;
        }

        // Is the given phone phone valid?
        const isPhoneValid = phoneRegex.test(phone);

        valid = isPhoneValid;

        setState((prevState) => ({ ...prevState, error: !isPhoneValid ? 'invalid-phone-number' : '' }));

        return valid;
    };

    const getVerificationCode = async (event: Event): Promise<void> => {
        event.preventDefault();

        // Validate
        if (!validate()) {
            return;
        }

        setState((prevState) => ({ ...prevState, isVerifying: true, codeSent: false, code: '' }));

        try {
            await ProfileApi.savePhoneNumber({ phone: state.phone });
            setState((prevState) => ({ ...prevState, codeSent: true }));
            codeInput.current.focus();
        } catch (error: any) {
            if (error instanceof ApiError) {
                setState((prevState) => ({ ...prevState, error: error.message.error }));
            }
        }

        setState((prevState) => ({ ...prevState, isVerifying: false }));
    };

    const handleSubmit = async (event: Event): Promise<void> => {
        const { code, codeSent } = state;

        // Prevent form submitting
        event.preventDefault();

        if (!codeSent) {
            getVerificationCode(event);

            return;
        }

        if (!validate()) {
            phoneInput.current.focus();
            return;
        }

        if (!code) {
            codeInput.current.focus();
            return;
        }

        setState((prevState) => ({ ...prevState, isEnabling: true }));

        try {
            await ProfileApi.enablePhoneAuthentication({ code });
            await getPhoneSettings();
        } catch (error: any) {
            if (error instanceof ApiError) {
                setState((prevState) => ({ ...prevState, error: error.message.error }));
            }
        }

        setState((prevState) => ({ ...prevState, isEnabling: false }));
    };

    const { phone, code, codeSent, error, isVerifying, isEnabling } = state;

    return (
        <Card className="card-wide">
            <CardBody>
                <form>
                    <h4 className="margin-bottom-half">{t('2fa.phone.enable.title')}</h4>
                    <span>{t('2fa.phone.enable.description')}</span>
                    <div className="form-group margin-top-large">
                        <TextInput
                            id="phone-number"
                            type="tel"
                            leadingIcon="phone"
                            value={phone}
                            label={t('2fa.phone.phone-number')}
                            className="margin-top width"
                            onChange={(phone): void => setState({ ...state, phone, error: '', codeSent: false })}
                            hasError={error === 'invalid-phone-number'}
                            descenderText={t('errors.invalid-phone-number')}
                            forwardRef={phoneInput}
                        />
                        <div
                            className={classNames('card-buttons', {
                                'margin-top-half': error !== 'invalid-phone-number',
                            })}
                        >
                            <div className="feedback-status">
                                {isVerifying && (
                                    <div className="input-row">
                                        <SpinnerIcon
                                            id="spinner"
                                            className="margin-right-half"
                                            size={18}
                                            color={'var(--color-primary-base)'}
                                        />
                                        {t('2fa.phone.requesting-code')}
                                    </div>
                                )}
                                {codeSent && (
                                    <div className="input-row">
                                        <JamfIcon
                                            name="checkCircle"
                                            width={18}
                                            className="text-success margin-right-half"
                                        />
                                        {t('2fa.phone.enable.code-sent')}
                                    </div>
                                )}
                            </div>
                            <Button
                                styleType="secondary"
                                /*@ts-ignore*/
                                onClick={getVerificationCode}
                                isDisabled={isVerifying}
                                label={t('2fa.phone.disable.request-code')}
                                isSubmit={!codeSent}
                            ></Button>
                        </div>
                    </div>
                    {codeSent && (
                        <div className="margin-top-large">
                            <TextInput
                                label={t('2fa.phone.code.label')}
                                value={code}
                                leadingIcon="lock"
                                onChange={(code): void => setState({ ...state, code, error: '' })}
                                forwardRef={codeInput}
                                hasError={error === 'phone-validation-failed'}
                                descenderText={t('errors.invalid-sms-token')}
                                className="width"
                            />
                            <div
                                className={classNames('card-buttons', {
                                    'margin-top-half': error !== 'phone-validation-failed',
                                })}
                            >
                                <Button
                                    isSubmit={true}
                                    /*@ts-ignore*/
                                    onClick={handleSubmit}
                                    isDisabled={isEnabling}
                                    isLoading={isEnabling}
                                    label={t('global.enable')}
                                />
                            </div>
                        </div>
                    )}
                </form>
            </CardBody>
        </Card>
    );
};

export default TwoFactorPhoneEnable;
