const ROUTES = {
    LOGIN: {
        ROOT: '/login',
        SETUP_TWOFACTOR_APP: '/login/authenticator',
        VERIFY: '/login/verify',
        RESEND_CODE: '/login/resend',
        CLIENT_LIST: '/login/client-list',
        CLIENT_INVITATIONS: '/login/client-invitations',
    },
    PROFILE: {
        ROOT: '/profile',
        NAME: '/profile/name',
        PASSWORD: '/profile/password',
        TWOFACTOR_APP: '/profile/two-factor-app',
        TWOFACTOR_PHONE: '/profile/two-factor-phone',
        TWOFACTOR_BACKUP_CODES: '/profile/two-factor-backup-codes',
        SESSIONS: '/profile/sessions',
        ACCOUNT_HISTORY: '/profile/account-history',
    },
    REGISTER: {
        ROOT: '/register',
        VERIFY: '/verify-email',
    },
    REQUESTPASSWORD: {
        ROOT: '/request-password',
        CONFIRM: '/request-password/confirm',
    },
    RESETPASSWORD: {
        ROOT: '/reset-password',
        CONFIRM: '/reset-password/confirm',
    },
    RESENDINVITATIONEMAIL: {
        ROOT: '/resend-invitation-email',
        CONFIRM: '/resend-invitation-email/confirm',
    },
};

export default ROUTES;
