import React, { FC, MutableRefObject, ReactElement, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileApi from '../../../api/ProfileApi';
import { Button, Card, CardBody, JamfIcon, SpinnerIcon, TextInput } from '@jamf/design-system-react';
import classNames from 'classnames';
import { ApiError } from '../../../api/ApiError';

interface TwoFactorPhoneDisableProps {
    getPhoneSettings: () => Promise<void>;
}

const TwoFactorPhoneDisable: FC<TwoFactorPhoneDisableProps> = ({ getPhoneSettings }): ReactElement => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        code: '',
        error: '',
        codeSent: false,
        isRequesting: false,
        isDisabling: false,
    });

    const codeInput: MutableRefObject<any> = useRef();

    const autoFocus = (): void => {
        codeInput.current.focus();
    };

    const requestToken = async (event: any): Promise<void> => {
        event.preventDefault();

        setState((prevState) => ({
            ...prevState,
            isRequesting: true,
            codeSent: false,
        }));

        try {
            await ProfileApi.requestPhoneCode();
            setState((prevState) => ({
                ...prevState,
                codeSent: true,
            }));
        } catch (error: any) {
            if (error instanceof ApiError) {
                // Set the error state and reset the code
                setState((prevState) => ({
                    ...prevState,
                    error: error.message,
                }));
            }
        }

        autoFocus();

        setState((prevState) => ({
            ...prevState,
            isRequesting: false,
        }));
    };

    const disableAuth = async (): Promise<void> => {
        const { code } = state;

        // Do nothing if the code field is blank, instead re-focus
        if (!code) {
            autoFocus();

            return;
        }

        setState((prevState) => ({
            ...prevState,
            isDisabling: true,
        }));

        try {
            await ProfileApi.disablePhoneAuthentication({ code });
            await getPhoneSettings();
        } catch (error: any) {
            if (error instanceof ApiError) {
                setState((prevState) => ({
                    ...prevState,
                    error: error.message.error,
                }));
            }
        }

        setState((prevState) => ({
            ...prevState,
            isDisabling: false,
        }));
    };

    const handleSubmit = (event: any): void => {
        event.preventDefault();
        disableAuth();
    };
    const { codeSent, code, error, isRequesting, isDisabling } = state;

    return (
        <Card className="card-wide">
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <h4 className="margin-bottom-half">{t('2fa.phone.disable.title')}</h4>
                    <span>{t('2fa.phone.disable.description')}</span>
                    <div
                        className={classNames('card-buttons margin-top-half', {
                            'margin-bottom': !error && codeSent,
                        })}
                    >
                        <div className="feedback-status">
                            {isRequesting && (
                                <div className="input-row">
                                    <SpinnerIcon id="spinner" size={18} color={'var(--color-primary-base)'} />
                                    {t('2fa.phone.requesting-code')}
                                </div>
                            )}
                            {codeSent && (
                                <div className="input-row">
                                    <JamfIcon name="checkCircle" width={18} className="text-success" />
                                    {t('2fa.phone.disable.code-sent')}
                                </div>
                            )}
                        </div>
                        <Button
                            styleType="secondary"
                            onClick={requestToken}
                            label={t('2fa.phone.disable.request-code')}
                        ></Button>
                    </div>

                    {codeSent && (
                        <>
                            <div className="margin-top margin-bottom-half">
                                <TextInput
                                    id="phone-code"
                                    leadingIcon="lock"
                                    type="text"
                                    value={code}
                                    label={t('2fa.phone.code.label')}
                                    onChange={(code): void => setState({ ...state, code, error: '' })}
                                    hasError={!!error}
                                    descenderText={t('errors.mobile-code-invalid')}
                                    forwardRef={codeInput}
                                    className="width"
                                />
                            </div>
                            <div className="card-buttons">
                                <Button
                                    isSubmit={true}
                                    styleType="danger"
                                    isDisabled={!codeSent || isDisabling}
                                    isLoading={isDisabling}
                                    label={t('global.disable')}
                                ></Button>
                            </div>
                        </>
                    )}
                </form>
            </CardBody>
        </Card>
    );
};

export default TwoFactorPhoneDisable;
