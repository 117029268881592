import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from '../../locales/en.json';
import nl from '../../locales/nl.json';
import de from '../../locales/de.json';
import es from '../../locales/es.json';
import fr from '../../locales/fr.json';
import ja from '../../locales/ja.json';

const resources = {
    en: {
        translation: en,
    },
    nl: {
        translation: nl,
    },
    de: {
        translation: de,
    },
    es: {
        translation: es,
    },
    fr: {
        translation: fr,
    },
    ja: {
        translation: ja,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: false,
        fallbackLng: 'en',
        whitelist: ['en', 'nl', 'de', 'es', 'fr', 'ja'],
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;
