import React, { FC, useState } from 'react';
import ROUTES from '../../../utilities/routes';
import ContentHeader from '../../Content/ContentHeader';
import BackupCodesDisable from './BackupCodesDisable';
import BackupCodesEnable from './BackupCodesEnable';
import { useTranslation, Trans } from 'react-i18next';
import { SpinnerIcon, Card, CardBody, Button } from '@jamf/design-system-react';

interface BackupCodesProps {
    backupCodesStatus: boolean;
    backupCodesRemaining: number;
    get2FASettings: () => void;
    isLoading: boolean;
}

const BackupCodes: FC<BackupCodesProps> = ({ backupCodesStatus, backupCodesRemaining, get2FASettings, isLoading }) => {
    const { t } = useTranslation();

    const [showGenerateCodes, setShowGenerateCodes] = useState(!backupCodesStatus);

    return (
        <div>
            <ContentHeader heading={t('2fa.backupcodes.title')} returnTo={ROUTES.PROFILE.ROOT} />
            {isLoading ? (
                <div className="spinner-middle">
                    <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                </div>
            ) : (
                <>
                    {!showGenerateCodes ? (
                        <>
                            <Card className="card-wide">
                                <CardBody>
                                    <h4 className="margin-bottom-half">{t(`2fa.backupcodes.title`)}</h4>
                                    <span>{t('2fa.backupcodes.description')}</span>
                                    <div className="setting-section margin-top">
                                        <span className="setting-icon enabled"></span>
                                        <span className="section-text">
                                            {t('global.enabled')} -{' '}
                                            <Trans i18nKey="2fa.backupcodes.count">{{ backupCodesRemaining }})</Trans>
                                        </span>
                                    </div>
                                    <div className="card-buttons">
                                        <Button
                                            styleType="secondary"
                                            label={t('2fa.backupcodes.re-generate')}
                                            onClick={() => setShowGenerateCodes(true)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <BackupCodesDisable get2FASettings={get2FASettings} />
                        </>
                    ) : (
                        <BackupCodesEnable
                            get2FASettings={get2FASettings}
                            showGenerateCodes={showGenerateCodes}
                            backupCodesStatus={backupCodesStatus}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default BackupCodes;
