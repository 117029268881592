import React, { FC, useContext, useEffect, useState } from 'react';
import AuthCard from './AuthCard/AuthCard';
import { Button, CardBody, CardHeader, CardTitle, JamfIcon, SpinnerIcon } from '@jamf/design-system-react';
import { Trans, useTranslation } from 'react-i18next';
import { Errors, Invitation, Invitations } from '../../api/models/Data.model';
import ProfileApi from '../../api/ProfileApi';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../utilities/routes';
import AuthenticationApi from '../../api/AuthenticationApi';
import { EnvContext } from '../../context/EnvContext';
import InvitationList from './InvitationList/InvitationList';
import { ApiError } from '../../api/ApiError';

const ClientInvitations: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { supportEmail } = useContext(EnvContext);

    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const [errors, setErrors] = useState<Errors[]>([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const getInvitations = async (): Promise<void> => {
        try {
            const invites: Invitations = await ProfileApi.getInvitations();
            setErrors(invites.errors);
            setInvitations(invites.invitations);
        } catch (error) {
            if (error instanceof ApiError) {
                setError(error.message.error);
            }
        }
        setIsLoading(false);
    };

    const goToProfile = () => {
        history.push(ROUTES.PROFILE.ROOT);
    };

    const goToAccounts = () => {
        history.push(ROUTES.LOGIN.CLIENT_LIST);
    };

    const logout = async () => {
        await AuthenticationApi.logout();
        history.push(ROUTES.LOGIN.ROOT);
    };

    useEffect(() => {
        getInvitations();
    }, []);

    return (
        <AuthCard>
            <CardHeader className="card-header">
                <CardTitle className="card-title">{t('invitation-list.title')}</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
                {isLoading && <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />}
                {!isLoading && error && (
                    <div className="alert danger">
                        <JamfIcon width={24} name="error" />
                        <div className="flex-column">
                            <span className="title">{t('list.error-title-generic')}</span>
                            <span>
                                <Trans i18nKey={'list.error-hint'}>{{ supportEmail }}</Trans>
                            </span>
                        </div>
                    </div>
                )}
                {!isLoading && (invitations.length >= 0 || errors.length > 0) && (
                    <InvitationList invitations={invitations} errors={errors} />
                )}
                {!isLoading && !error && errors.length === 0 && invitations.length === 0 && (
                    <div className="flex-column">
                        <span>{t('invitation-list.no-invitations')}</span>
                    </div>
                )}
                <div className="input-row justify-flex-end margin-top-large">
                    <Button leadingIcon styleType="secondary" label={t('list.profile-button')} onClick={goToProfile}>
                        {' '}
                        <JamfIcon name="user" width="24" height="24" />
                    </Button>
                    <Button leadingIcon styleType="secondary" label={t('list.account-button')} onClick={goToAccounts}>
                        <JamfIcon name="users" width="24" height="24" />
                    </Button>
                    <Button leadingIcon styleType="secondary" label={t('logout.cta')} onClick={logout}>
                        {' '}
                        <JamfIcon name="logout" width="24" height="24" />
                    </Button>
                </div>
            </CardBody>
        </AuthCard>
    );
};

export default ClientInvitations;
