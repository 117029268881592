import React, { FC, ReactElement } from 'react';
import TwoFactorPhoneDisable from './TwoFactorPhoneDisable';
import TwoFactorPhoneEnable from './TwoFactorPhoneEnable';
import { useTranslation } from 'react-i18next';
import ContentHeader from '../../Content/ContentHeader';
import ROUTES from '../../../utilities/routes';
import { Card, CardBody, SpinnerIcon } from '@jamf/design-system-react';

interface TwoFactorPhoneProps {
    phoneNumber: string;
    phoneStatus: boolean;
    getPhoneSettings: () => Promise<void>;
    isLoading: boolean;
}

const TwoFactorPhone: FC<TwoFactorPhoneProps> = ({
    phoneNumber,
    phoneStatus,
    getPhoneSettings,
    isLoading,
}): ReactElement => {
    const { t } = useTranslation();

    return (
        <div>
            <ContentHeader heading={t('2fa.phone.title')} returnTo={ROUTES.PROFILE.ROOT} />
            {isLoading ? (
                <div className="spinner-middle">
                    <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                </div>
            ) : phoneStatus ? (
                <div>
                    <Card className="card-wide margin-bottom">
                        <CardBody className="margin-bottom">
                            <h4 className="margin-bottom-half">{t(`2fa.phone.description`)}</h4>
                            <span>{t('2fa.phone.hint')}</span>
                            <div className="setting-section margin-top">
                                <span className={`setting-icon ${phoneStatus ? 'enabled' : 'disabled'}`}></span>
                                <span className="section-text">
                                    {phoneStatus ? t('global.enabled') : t('global.disabled')}
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                    <TwoFactorPhoneDisable getPhoneSettings={getPhoneSettings} />
                </div>
            ) : (
                <TwoFactorPhoneEnable phoneNumber={phoneNumber} getPhoneSettings={getPhoneSettings} />
            )}
        </div>
    );
};

export default TwoFactorPhone;
