import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './js/components/App'
import './js/utilities/i18n'

const app = document.getElementById('app')

if (app) {
    ReactDOM.render(
        <Router basename={process.env.REACT_APP_BASE_PATH}>
            <App />
        </Router>,
        app
    )
}
