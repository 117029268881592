import { Api } from './Api';
import { Login, RequestPassword, ResetPassword, ResendVerificationEmail } from './models/Data.model';
import { LoginResponse, Response, AuthorizationUrlResponse } from './models/ApiResponse.model';
import { Authorization } from './models/ApiTypes';

class AuthenticationApi extends Api {
    login(options: Login): Promise<LoginResponse> {
        return this.post('/login', options);
    }

    logout(): Promise<Response> {
        return this.post('/logoff');
    }

    requestPassword(options: RequestPassword): Promise<Response> {
        return this.post('/password-reset/request', options);
    }

    resetPassword(options: ResetPassword): Promise<Response> {
        return this.post('/password-reset', options);
    }

    getAuthorizationUrl(type: Authorization): Promise<AuthorizationUrlResponse> {
        return this.get('/login/' + type);
    }

    resendInvitationEmail(options: ResendVerificationEmail): Promise<Response> {
        return this.post('/resend-invitation-email', options);
    }
}

export default new AuthenticationApi();
