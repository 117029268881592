import React, { FC, useMemo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ContentHeader from '../../Content/ContentHeader';
import ROUTES from '../../../utilities/routes';
import { CellProps, useTable, useFlexLayout, useResizeColumns, usePagination } from 'react-table';
import moment from 'moment';
import { Autocomplete, Button, JamfIcon, SpinnerIcon } from '@jamf/design-system-react';
import AuditApi from '../../../api/AuditApi';
import { AccountHistorySettings } from '../ProfileContainer';
import i18n from '../../../utilities/i18n';

interface AccountHistoryProps {
    accountHistorySettings: AccountHistorySettings;
    setAccountHistorySetttings: React.Dispatch<React.SetStateAction<AccountHistorySettings>>;
}

const AccountHistory: FC<AccountHistoryProps> = ({ accountHistorySettings, setAccountHistorySetttings }) => {
    const { t } = useTranslation();

    const { data, currentPage, from, lastPage, nextPageUrl, prevPageUrl, to, total, isLoading } =
        accountHistorySettings;

    const columns = useMemo(
        () => [
            {
                Header: t('audits.headers.events'),
                accessor: 'event',
                Cell: ({ cell }: CellProps<any>): ReactElement => (
                    <span className="label">{t(`audits.events.${cell.value}`)}</span>
                ),
            },
            {
                Header: t('audits.headers.date'),
                accessor: 'timestamp',
                Cell: ({ cell }: CellProps<any>): ReactElement => (
                    <span>{moment(cell.value).locale(i18n.language).fromNow()}</span>
                ),
            },
            {
                Header: t('audits.headers.location'),
                accessor: 'location',
            },
        ],
        [t]
    );

    const getDataFromPage = async (url: string): Promise<void> => {
        setAccountHistorySetttings((prevState: AccountHistorySettings) => {
            return { ...prevState, isLoading: true };
        });
        const [, search] = url.split('?');
        const response = await AuditApi.goToPage(`?${search}`);

        setAccountHistorySetttings((prevState: AccountHistorySettings) => {
            return { ...prevState, ...response, isLoading: false };
        });
    };

    const handlePageNumberChange = async (value: string): Promise<void> => {
        if (value !== '' && value !== currentPage.toString()) {
            const pageNumber: string = value;

            if (pageNumber !== currentPage.toString()) {
                getDataFromPage(`?page=${pageNumber}`);
            }
        }
    };

    const pageNumberValues = useMemo((): string[] => {
        const pages: string[] = [];
        for (let i = 1; i <= lastPage; i++) {
            pages.push(i.toString());
        }
        return pages;
    }, [lastPage]);

    // const data: Audit[] = useMemo(() => accountHistorySettings.data, [accountHistorySettings.data]);

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        }),
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn,
            manualPagination: true,
        },
        useFlexLayout,
        useResizeColumns,
        usePagination
    );

    return (
        <div className="table-container">
            <ContentHeader heading={t('audits.title')} returnTo={ROUTES.PROFILE.ROOT} />
            <div className="table-view">
                <nav className="pagination">
                    {total > 0 && (
                        <div className="pagination-controls">
                            <span className="control-wrapper">
                                <Button
                                    leadingIcon
                                    className="icon-only previous-page"
                                    styleType="ghost"
                                    isDisabled={prevPageUrl ? false : true}
                                    onClick={(): Promise<void> => getDataFromPage(prevPageUrl)}
                                >
                                    <JamfIcon name="arrow" width="24" height="24" />
                                </Button>
                            </span>
                            <span className="control-wrapper">
                                <Autocomplete
                                    options={pageNumberValues}
                                    openMenuOnFocus={true}
                                    isStrictSelect={true}
                                    value={currentPage.toString()}
                                    onChangeCallback={handlePageNumberChange}
                                    className="page-number-autcomplete"
                                />
                            </span>
                            <span className="control-wrapper">
                                <Button
                                    leadingIcon
                                    className="icon-only next-page"
                                    styleType="ghost"
                                    isDisabled={nextPageUrl ? false : true}
                                    onClick={(): Promise<void> => getDataFromPage(nextPageUrl)}
                                >
                                    <JamfIcon name="arrow" width="24" height="24" />
                                </Button>
                            </span>
                            <span className="control-wrapper">{`${from} - ${to} of ${total}`}</span>
                        </div>
                    )}
                    {!isLoading && (!total || total === 0) && <span>{t('audits.table-no-results')}</span>}
                    {isLoading && (
                        <SpinnerIcon className="margin-right-auto" id="spinner" color={'var(--color-primary-base)'} />
                    )}
                </nav>

                <table {...getTableProps()}>
                    <thead className="header-row">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()} className="header">
                                        {column.render('Header')}
                                        {/*@ts-ignore*/}
                                        <div {...column.getResizerProps()} className="resize" />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="row">
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className="cell" {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AccountHistory;
