import React, { Component, createRef, MutableRefObject, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import zxcvbn from 'zxcvbn';
import { withTranslation, WithTranslation } from 'react-i18next';
import AuthenticationApi from '../../api/AuthenticationApi';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { CardHeader, Button, CardTitle, CardBody, TextInput, ProgressBar, JamfIcon } from '@jamf/design-system-react';
import PASSWORD_STATUS from '../../utilities/passwordStatus';
import { ApiError } from '../../api/ApiError';

interface ResetPasswordLocationState {
    email: string;
    token: string;
    alert: string;
}

class ResetPassword extends Component<WithTranslation & RouteComponentProps<any, any, ResetPasswordLocationState>> {
    state = {
        email: '',
        error: '',
        isLoading: false,
        password: '',
        passwordConfirmation: '',
        passwordStrength: 0,
        passwordWarning: '',
        token: '',
        alert: '',
    };

    passwordInput: MutableRefObject<any> = createRef();
    passwordConfirmInput: MutableRefObject<any> = createRef();

    componentDidMount(): void {
        const { history, location } = this.props;

        // t = token
        const { email, t } = queryString.parse(location.search);

        // If no email or token are given, refer the user to the login page
        if (
            (email === undefined || t === undefined) &&
            (location.state.email === undefined || location.state.token === undefined)
        ) {
            history.push(ROUTES.LOGIN.ROOT);
        }

        this.autoFocus();

        if (email && t) {
            this.setState({
                email: email,
                token: t,
            });
        } else if (location.state.email && location.state.token) {
            this.setState({
                email: location.state.email,
                token: location.state.token,
                alert: location.state.alert,
            });
        }
    }

    handlePasswordChange = (password: string): void => {
        const {
            score,
            feedback: { warning },
        } = zxcvbn(password, [this.state.email]);
        this.setState({
            passwordStrength: score,
            passwordWarning: warning,
            password,
            error: '',
        });
    };

    handleSubmit = (event: any): void => {
        event.preventDefault();
        this.resetPassword();
    };

    goBack = (): void => {
        this.props.history.push(`${ROUTES.LOGIN.ROOT}`);
    };

    resetPassword = async (): Promise<void> => {
        const { email, password, passwordConfirmation, passwordStrength, passwordWarning, token } = this.state;
        const { history } = this.props;

        if (!password || !passwordConfirmation) {
            this.autoFocus();
            return;
        }

        if (password !== passwordConfirmation) {
            this.setState({ error: 'password-no-match' });
            this.passwordConfirmInput.current.focus();
            return;
        }

        if (passwordStrength < 2) {
            this.setState({ error: passwordWarning ? 'common-password' : 'weak-password' });
            this.passwordInput.current.focus();
            return;
        }

        if (passwordWarning && passwordStrength < 3) {
            this.setState({ error: 'common-password' });
            this.passwordInput.current.focus();
            return;
        }

        this.setState({
            isLoading: true,
        });

        try {
            await AuthenticationApi.resetPassword({ email, password, passwordConfirmation, token });

            this.setState({
                isLoading: false,
            });

            // Refer the user to a confirmation
            history.push(ROUTES.RESETPASSWORD.CONFIRM);
        } catch (error) {
            if (error instanceof ApiError) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }

                this.autoFocus();

                // Set the error state and reset the password
                this.setState({
                    error: error.message.error,
                    isLoading: false,
                    password: '',
                    passwordConfirmation: '',
                });
            }
        }
    };

    autoFocus = (): void => {
        if (this.passwordInput.current) {
            if (this.passwordInput.current.value === '') {
                this.passwordInput.current.focus();
                return;
            }
        }

        if (this.passwordConfirmInput.current) {
            if (this.passwordConfirmInput.current.value === '') {
                this.passwordConfirmInput.current.focus();
                return;
            }
        }
    };

    render(): ReactElement {
        const { error, isLoading, password, passwordConfirmation, passwordStrength, passwordWarning, alert } =
            this.state;
        const { t } = this.props;

        return (
            <AuthCard>
                <CardHeader className="card-header justify-flex-start">
                    <Button leadingIcon styleType="secondary" className="back-button" onClick={this.goBack}>
                        <JamfIcon name="arrow" width="24" height="24" />
                    </Button>
                    <CardTitle className="card-title">{t('reset-password.title')}</CardTitle>
                </CardHeader>
                <CardBody className="card-body">
                    <form>
                        {alert && (
                            <div className="alert warning text-only">
                                <JamfIcon name="warning" />
                                <span>{t(`errors.${alert}`)}</span>
                            </div>
                        )}
                        <TextInput
                            id="reset-password"
                            label={t('reset-password.label')}
                            value={password}
                            onChange={(password): void => this.handlePasswordChange(password)}
                            hasError={!!error}
                            type="password"
                            leadingIcon="lock"
                            className="margin-bottom-half width"
                            forwardRef={this.passwordInput}
                        ></TextInput>
                        {password && (
                            <ProgressBar
                                className="progress-bar margin-bottom-half"
                                barColor={PASSWORD_STATUS[passwordStrength].color}
                                labelColor={passwordWarning ? 'danger' : PASSWORD_STATUS[passwordStrength].color}
                                percentComplete={PASSWORD_STATUS[passwordStrength].percent}
                                label={t(
                                    error === 'weak-password'
                                        ? 'errors.weak-password'
                                        : error === 'common-password'
                                        ? 'errors.common-password'
                                        : `password.strength.${
                                              passwordWarning ? 'common' : PASSWORD_STATUS[passwordStrength].t
                                          }`
                                )}
                            ></ProgressBar>
                        )}
                        <TextInput
                            id="confirm-password"
                            label={t('reset-password.confirm-label')}
                            value={passwordConfirmation}
                            onChange={(passwordConfirmation): void =>
                                this.setState({ passwordConfirmation, error: '' })
                            }
                            hasError={!!error && error !== 'weak-password' && error !== 'common-password'}
                            descenderText={t(`errors.${error}`)}
                            type="password"
                            leadingIcon="lock"
                            className="margin-bottom margin-top width"
                            forwardRef={this.passwordConfirmInput}
                        ></TextInput>
                        <div className="input-row justify-flex-end">
                            <Button
                                isSubmit={true}
                                onClick={this.handleSubmit}
                                label={t('global.save')}
                                className="margin-top-half"
                                isDisabled={isLoading}
                                isLoading={isLoading}
                            ></Button>
                        </div>
                    </form>
                </CardBody>
            </AuthCard>
        );
    }
}

export default withRouter(withTranslation()(ResetPassword));
