import React, { Fragment, useCallback, useContext, useEffect, useRef, useState, FC } from 'react';
import AuthCard from './AuthCard/AuthCard';
import ROUTES from '../../utilities/routes';
import { useLocation, useHistory } from 'react-router-dom';
import AuthenticationApi from '../../api/AuthenticationApi';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation, Trans } from 'react-i18next';
import { Button, TextInput, Checkbox, CardBody, CardHeader, CardTitle, JamfIcon } from '@jamf/design-system-react';
import { ApiError } from '../../api/ApiError';

type SystemType = 'APP' | 'PHONE' | 'BACKUPCODES';

interface LoginState {
    email?: string;
    password?: string;
    remember?: boolean;
    phoneNumber?: string;
    type?: SystemType;
    hasAppAuth?: boolean;
    hasPhoneAuth?: boolean;
    hasBackupCodesAuth?: boolean;
}

interface State {
    trustBrowser?: boolean;
    error?: string;
    isLoading?: boolean;
    code?: string;
}

const getSystem = (type: SystemType | undefined) => {
    switch (type) {
        case 'APP':
            return '';
        case 'PHONE':
            return 'mobile';
        case 'BACKUPCODES':
            return 'backup-code';
        default:
            return undefined;
    }
};

const LoginTwoFactor: FC = () => {
    const context = useContext(AuthContext);

    const { t } = useTranslation();

    const location = useLocation() as any;
    const history = useHistory();

    // State of this component
    const [state, setState] = useState<State>({
        trustBrowser: false,
        error: '',
        isLoading: false,
        code: '',
    });

    // State passed from a Login-component
    const [loginState, setLoginState] = useState<LoginState>({
        email: '',
        password: '',
        remember: false,
        phoneNumber: '',
        type: 'APP',
        hasAppAuth: false,
        hasPhoneAuth: false,
        hasBackupCodesAuth: false,
    });

    const [isResending, setResendingState] = useState(false);

    // Input reference
    const codeInput = useRef<HTMLInputElement>(null);

    /**
     * Focus on the input
     */
    const autoFocus = () => {
        if (codeInput && codeInput.current) {
            codeInput.current.focus();
        }
    };

    /**
     * Effect to update the loginState
     */
    useEffect(() => {
        if (location.state === undefined) {
            history.push(ROUTES.LOGIN.ROOT);
            return;
        }

        autoFocus();

        setLoginState(location.state);
    }, [history, location.state]);

    /**
     * Resend the code to the phone
     */
    const resendCode = useCallback(
        async (event?: any) => {
            const { email, password, remember } = loginState;

            if (event) {
                event.preventDefault();
            }

            setResendingState(true);

            try {
                await AuthenticationApi.login({ email, password, remember, system: 'mobile' });
            } catch (error) {
                // TODO handle this error
            }

            setResendingState(false);
        },
        [loginState]
    );

    /**
     * Handle the form submit
     */
    async function handleSubmit(event: any) {
        const { code, trustBrowser } = state;
        const { email, password, remember, type } = loginState;

        // Prevent default behavior of form submit
        event.preventDefault();

        // Do nothing when no code is entered
        if (code === '') {
            if (codeInput.current) {
                codeInput.current.focus();
            }
            return;
        }

        // Set the loading state
        setState({ ...state, isLoading: true });

        try {
            // Sign in
            await AuthenticationApi.login({ email, password, remember, code, trustBrowser, system: getSystem(type) });

            // Authenticate
            const user = await context.getProfile();

            if (user == null) {
                return;
            }

            // Check if we need to refer the user to a client
            const clientId = localStorage.getItem('clientId');

            let url = `${process.env.REACT_APP_ACCOUNT_URL}/flow/authorize-complete`;

            if (clientId) {
                // Remove the Client ID from the Local Storage
                localStorage.removeItem('clientId');

                // Refer the user to the client
                url += `?clientId=${clientId}`;
            }

            window.location.href = url;
        } catch (error) {
            if (error instanceof ApiError) {
                setState({
                    ...state,
                    error: error.message.error,
                    isLoading: false,
                });

                autoFocus();
            }
        }
    }

    /**
     * Switch between types
     */
    const switchType = (type: SystemType) => {
        setLoginState({
            ...loginState,
            type,
        });

        setState({
            ...state,
            error: '',
            code: '',
        });

        // If switching to SMS, have a code sent
        if (type === 'PHONE') {
            resendCode();
        }

        autoFocus();
    };

    const goBack = () => {
        history.push(ROUTES.LOGIN.ROOT);
    };

    const renderDescription = () => {
        const { type } = loginState;
        let translationKey = '2fa.app.code.hint';

        if (type === 'PHONE') {
            translationKey = '2fa.phone.code.hint';
        }

        if (type === 'BACKUPCODES') {
            translationKey = '2fa.backupcodes.code.hint';
        }

        return (
            <Fragment>
                <p>
                    <Trans i18nKey={translationKey}></Trans>
                </p>
                {loginState.type === 'PHONE' && (
                    <Button
                        styleType="secondary"
                        label={t('2fa.phone.code.button')}
                        onClick={resendCode}
                        isLoading={isResending}
                        isDisabled={isResending}
                        className="resend-code-button"
                    ></Button>
                )}
            </Fragment>
        );
    };

    const renderSwitchButtons = () => {
        const { hasBackupCodesAuth, hasAppAuth, hasPhoneAuth } = loginState;

        // If at least two of the three 2factor methods are enabled
        if ((hasBackupCodesAuth && (hasAppAuth || hasPhoneAuth)) || (hasAppAuth && hasPhoneAuth)) {
            return (
                <Fragment>
                    <div className="input-row alt-login-buttons margin-top">
                        {loginState.type !== 'APP' && loginState.hasAppAuth && (
                            <Button
                                onClick={() => switchType('APP')}
                                styleType="secondary"
                                label={t('2fa.app.use')}
                            ></Button>
                        )}
                        {loginState.type !== 'PHONE' && loginState.hasPhoneAuth && (
                            <Button
                                onClick={() => switchType('PHONE')}
                                styleType="secondary"
                                label={t('2fa.phone.use')}
                            ></Button>
                        )}
                        {loginState.type !== 'BACKUPCODES' && loginState.hasBackupCodesAuth && (
                            <Button
                                onClick={() => switchType('BACKUPCODES')}
                                styleType="secondary"
                                label={t('2fa.backupcodes.use')}
                            ></Button>
                        )}
                    </div>
                </Fragment>
            );
        } else {
            return null;
        }
    };

    return (
        <Fragment>
            <AuthCard>
                <CardHeader className="card-header justify-flex-start">
                    <Button leadingIcon styleType="secondary" className="back-button" onClick={goBack}>
                        <JamfIcon name="arrow" width="24" height="24" />
                    </Button>
                    <CardTitle className="card-title">
                        {loginState.type && t(`2fa.${loginState.type.toLowerCase()}.title-verify`)}
                    </CardTitle>
                </CardHeader>
                <CardBody className="card-body">
                    <form>
                        {renderDescription()}
                        <TextInput
                            value={state.code}
                            onChange={(code) => setState({ ...state, code })}
                            forwardRef={codeInput}
                            label={t('2fa.app.code.label')}
                            hasError={!!state.error}
                            descenderText={t(`errors.${state.error}`)}
                            className="margin-top margin-bottom width"
                            leadingIcon="lock"
                        ></TextInput>
                        <div className="input-row padding-top-half">
                            <Checkbox
                                onChange={(trustBrowser: boolean) => setState({ ...state, trustBrowser })}
                                checked={state.trustBrowser}
                                label={t('login.trust-browser')}
                            ></Checkbox>
                            <Button
                                label={t('global.verify')}
                                isLoading={state.isLoading}
                                isDisabled={state.isLoading}
                                isSubmit={true}
                                onClick={handleSubmit}
                            ></Button>
                        </div>
                    </form>
                    {renderSwitchButtons()}
                </CardBody>
            </AuthCard>
        </Fragment>
    );
};

export default LoginTwoFactor;
