import React, { createRef, MutableRefObject, ReactElement, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, TextInput } from '@jamf/design-system-react';
import classNames from 'classnames';
import ProfileApi from '../../../api/ProfileApi';
import { ApiError } from '../../../api/ApiError';

interface TwoFactorAppDisable {
    get2FASettings: () => Promise<void>;
}

const TwoFactorAppDisable: FC<TwoFactorAppDisable> = ({ get2FASettings }): ReactElement => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        code: '',
        error: '',
        isDisabling: false,
    });

    const codeInput: MutableRefObject<any> = createRef();

    const autoFocus = (): void => {
        codeInput.current.focus();
    };

    const handleSubmit = async (event: any): Promise<void> => {
        const { code } = state;

        // Prevent default behaviour
        event.preventDefault();

        // Focus on the code field if it's left empty
        if (!code) {
            autoFocus();

            return;
        }

        setState((prevState) => ({
            ...prevState,
            isDisabling: true,
        }));

        try {
            await ProfileApi.disable2FA({ code });
            await get2FASettings();
        } catch (error: any) {
            if (error instanceof ApiError) {
                setState((prevState) => ({
                    ...prevState,
                    error: error.message.errors.code[0],
                }));
            }
        }

        setState((prevState) => ({
            ...prevState,
            isDisabling: false,
        }));
    };

    const { code, error, isDisabling } = state;

    return (
        <Card className="card-wide">
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <h4 className="margin-bottom-half">{t('2fa.app.disable.title')}</h4>
                    <span>{t('2fa.app.disable.hint')}</span>
                    <TextInput
                        leadingIcon="lock"
                        className={classNames('margin-top-large width', { 'margin-bottom-half': !error })}
                        value={code}
                        onChange={(code) => setState({ ...state, code, error: '' })}
                        forwardRef={codeInput}
                        label={t('2fa.app.code.label')}
                        hasError={!!error}
                        descenderText={t('errors.authenticator-code-invalid')}
                    />
                    <div className="card-buttons">
                        <Button
                            isSubmit={true}
                            styleType="danger"
                            isDisabled={isDisabling}
                            isLoading={isDisabling}
                            label={t('global.disable')}
                        />
                    </div>
                </form>
            </CardBody>
        </Card>
    );
};

export default TwoFactorAppDisable;
