import React, { FC, useState } from 'react';
import ProfileApi from '../../../api/ProfileApi';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Button } from '@jamf/design-system-react';

const BackupCodesDisable: FC<{ get2FASettings: () => void }> = ({ get2FASettings }) => {
    const [isDisabling, setDisablingState] = useState(false);
    const { t } = useTranslation();

    const disableBackupCodes = async () => {
        setDisablingState(true);

        try {
            await ProfileApi.disableBackupCodes();
            await get2FASettings();
        } catch (error) {
            return;
        }

        setDisablingState(false);
    };

    return (
        <Card className="card-wide margin-top">
            <CardBody>
                <h4 className="margin-bottom-half">{t('2fa.backupcodes.disable.title')}</h4>
                <span>{t('2fa.backupcodes.disable.description')}</span>
                <div className="card-buttons">
                    <Button
                        styleType="danger"
                        isDisabled={isDisabling}
                        isLoading={isDisabling}
                        onClick={disableBackupCodes}
                        label={t('global.disable')}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

export default BackupCodesDisable;
