import React, { Component, MutableRefObject, ReactElement } from 'react';
import ProfileApi from '../../api/ProfileApi';
import { AuthContext } from '../../context/AuthContext';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    CardBody,
    CardHeader,
    CardTitle,
    Image,
    SpinnerIcon,
    TextInput,
    Button,
    JamfIcon,
} from '@jamf/design-system-react';

class AuthenticatorSetup extends Component<WithTranslation & RouteComponentProps> {
    state = {
        code: '',
        error: '',
        isLoading: false,
        qrCode: '',
        secret: '',
    };

    static contextType = AuthContext;

    codeInput: MutableRefObject<any> = React.createRef();

    async componentDidMount(): Promise<void> {
        // Get the 2FA setup settings
        const { secret, qrCode } = await ProfileApi.get2FASetup();

        this.setState({
            qrCode: qrCode,
            secret: secret,
        });

        // Autofocus on the input
        this.autoFocus();
    }

    handleSubmit = (event: any): void => {
        event.preventDefault();
        this.verify2FA();
    };

    verify2FA = async (): Promise<void> => {
        const { code, secret } = this.state;

        // Do nothing if the code field is blank, instead re-focus
        if (!code) {
            this.autoFocus();

            return;
        }

        this.setState({
            isLoading: true,
        });

        try {
            // Try to enable 2FA...
            await ProfileApi.enable2FA({ code, secret });

            this.setState({
                isLoading: false,
            });

            this.referUser();
        } catch (error) {
            if (error instanceof Error) {
                if (process.env.NODE_ENV === 'development') {
                    console.log(error);
                }

                this.autoFocus();

                // Set the error state and reset the code
                this.setState({
                    code: '',
                    error: error.message,
                    isLoading: false,
                });
            }
        }
    };

    skip = async (): Promise<void> => {
        await ProfileApi.skip2FA();
        this.referUser();
    };

    autoFocus = (): void => {
        this.codeInput.current.focus();
    };

    goBack = (): void => {
        this.props.history.push(ROUTES.LOGIN.ROOT);
    };

    referUser = (): void => {
        // Check if we need to refer the user to a client
        const clientId = localStorage.getItem('clientId');

        let url = `${process.env.REACT_APP_ACCOUNT_URL}/flow/authorize-complete`;

        if (clientId) {
            // Remove the Client ID from the Local Storage
            localStorage.removeItem('clientId');

            // Refer the user to the client
            url += `?clientId=${clientId}`;
        }

        window.location.href = url;
    };

    render(): ReactElement {
        const { code, error, isLoading, qrCode } = this.state;
        const { t } = this.props;

        return (
            <AuthCard>
                <CardHeader className="card-header justify-flex-start">
                    <Button leadingIcon styleType="secondary" className="back-button" onClick={this.goBack}>
                        <JamfIcon name="arrow" width="24" height="24" />
                    </Button>
                    <CardTitle className="card-title">{t('2fa.app.title')}</CardTitle>
                </CardHeader>
                <CardBody className="card-body">
                    <h4>{t('2fa.app.enable.title')}</h4>
                    <span>{t('2fa.app.description')}</span>
                    <ol>
                        <li>{t('2fa.app.enable.guide.step-one')}</li>
                        <li>{t('2fa.app.enable.guide.step-two')}</li>
                        <li>
                            <Trans i18nKey={'2fa.app.enable.guide.step-three'}></Trans>
                        </li>
                    </ol>
                    <div className="qr-code">
                        {qrCode ? (
                            <Image src={qrCode} altText="QR Code for Two Factor authentication" />
                        ) : (
                            <div className="spinner-middle">
                                <SpinnerIcon id="spinner" color={'var(--color-primary-base)'}></SpinnerIcon>
                            </div>
                        )}
                    </div>
                    <form>
                        <TextInput
                            value={code}
                            isRequired={true}
                            onChange={(code): void => this.setState({ code, error: '' })}
                            forwardRef={this.codeInput}
                            descenderText={t('errors.authenticator-verification-invalid')}
                            hasError={!!error}
                            leadingIcon="lock"
                            className="margin-top margin-bottom width"
                            label={t('2fa.app.code.label')}
                        ></TextInput>
                        <div className="input-row justify-flex-end padding-top-half">
                            <Button styleType="secondary" label={t('2fa.app.skip')} onClick={this.skip}></Button>
                            <Button
                                styleType="primary"
                                label={t('global.enable')}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                                onClick={this.handleSubmit}
                                isSubmit={true}
                            ></Button>
                        </div>
                    </form>
                </CardBody>
            </AuthCard>
        );
    }
}

export default withRouter(withTranslation()(AuthenticatorSetup));
