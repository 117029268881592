import React, { FC, ReactElement } from 'react';
import ROUTES from '../../../utilities/routes';
import ContentHeader from '../../Content/ContentHeader';
import TwoFactorAppEnable from './TwoFactorAppEnable';
import TwoFactorAppDisable from './TwoFactorAppDisable';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, SpinnerIcon } from '@jamf/design-system-react';

interface TwoFactorAppProps {
    authenticatorStatus: boolean;
    get2FASettings: () => Promise<void>;
    isLoading: boolean;
}
const TwoFactorApp: FC<TwoFactorAppProps> = ({ authenticatorStatus, get2FASettings, isLoading }): ReactElement => {
    const { t } = useTranslation();

    return (
        <div>
            <ContentHeader heading={t('2fa.app.title')} returnTo={ROUTES.PROFILE.ROOT} />
            {isLoading ? (
                <div className="spinner-middle">
                    <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                </div>
            ) : (
                <>
                    {authenticatorStatus ? (
                        <>
                            <Card className="card-wide margin-bottom">
                                <CardBody className="margin-bottom">
                                    <h4 className="margin-bottom-half">{t(`2fa.app.title-secondary`)}</h4>
                                    <span>{t('2fa.app.description')}</span>
                                    <div className="setting-section margin-top">
                                        <span
                                            className={`setting-icon ${authenticatorStatus ? 'enabled' : 'disabled'}`}
                                        ></span>
                                        <span className="section-text">
                                            {authenticatorStatus ? t('global.enabled') : t('global.disabled')}
                                        </span>
                                    </div>
                                </CardBody>
                            </Card>
                            <TwoFactorAppDisable get2FASettings={get2FASettings} />{' '}
                        </>
                    ) : (
                        <TwoFactorAppEnable get2FASettings={get2FASettings} />
                    )}
                </>
            )}
        </div>
    );
};

export default TwoFactorApp;
