import React, { FC, ComponentType, useContext, ReactElement } from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ROUTES from '../utilities/routes';

interface AuthRouteProps extends RouteProps {
    component: ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const AuthRoute: FC<AuthRouteProps> = (props): ReactElement => {
    const { user, isAuthenticated } = useContext(AuthContext);

    let redirectPath = '';
    if (!isAuthenticated) {
        redirectPath = ROUTES.LOGIN.ROOT;
    }
    if (isAuthenticated && !user.emailVerifiedAt) {
        redirectPath = ROUTES.REGISTER.VERIFY;
    }

    if (redirectPath) {
        const renderComponent = (): ReactElement => <Redirect to={{ pathname: redirectPath }} />;
        return <Route {...props} component={renderComponent} />;
    } else {
        return <Route {...props} />;
    }
};

export default AuthRoute;
