import React, { Fragment, FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ROUTES from '../../../utilities/routes';
import AuthenticationApi from '../../../api/AuthenticationApi';
import { Session } from '../../../api/models/Data.model';
import { Card, CardBody, Button, JamfIcon } from '@jamf/design-system-react';
import moment from 'moment';
import i18n from '../../../utilities/i18n';

interface SessionEventProps {
    deleteSession: (id: number) => void;
    session: Session;
}

const SessionEvent: FC<SessionEventProps> = ({ deleteSession, session }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();
    const history = useHistory();

    const { id, agent, browser, platform, isCurrent, created, locations } = session;

    const deleteThisSession = async (id: number, isCurrent: boolean): Promise<void> => {
        setIsLoading(true);

        if (isCurrent) {
            await AuthenticationApi.logout();
            history.push(ROUTES.LOGIN.ROOT);
        } else {
            await deleteSession(id);

            setIsLoading(false);
        }
    };

    let title = `${browser}, ${platform}`;

    if (agent.includes('Postman')) {
        title = agent;
    }

    const date = moment(created).locale(i18n.language).fromNow();

    let location, ipAddress;
    if (locations.length) {
        location = locations[0].location;
        ipAddress = locations[0].ipAddress;
    }

    return (
        <Card className="session-card">
            <CardBody className="session-card-body">
                <div className="session-text">
                    <span className="title">
                        {title} {isCurrent && <span className="current">{t('sessions.current')}</span>}
                    </span>

                    <span className="description">
                        {location ? (
                            <Trans i18nKey="sessions.timestamp">
                                {{ date }} from {{ location }} ({{ ipAddress }})
                            </Trans>
                        ) : (
                            <Fragment>{date}</Fragment>
                        )}
                    </span>
                </div>
                <Button
                    onClick={(): Promise<void> => deleteThisSession(id, isCurrent)}
                    className="session-button"
                    label={t('global.delete')}
                    styleType="ghost"
                    leadingIcon
                    isLoading={isLoading}
                    isDisabled={isLoading}
                >
                    <JamfIcon name="trash" width="24" height="24" />
                </Button>
            </CardBody>
        </Card>
    );
};

export default SessionEvent;
