import React, { FC } from 'react';
import ROUTES from '../../../utilities/routes';
import SessionApi from '../../../api/SessionsApi';
import ContentHeader from '../../Content/ContentHeader';
import SessionEvent from './SessionEvent';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, JamfIcon, SpinnerIcon } from '@jamf/design-system-react';
import { SessionsSettings } from '../ProfileContainer';

interface SessionsProps {
    getSessions: () => void;
    sessionsSettings: SessionsSettings;
}

const Sessions: FC<SessionsProps> = ({ getSessions, sessionsSettings }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { sessions, isLoading } = sessionsSettings;

    const deleteSession = async (id: number) => {
        await SessionApi.deleteSession(id);

        getSessions();
    };

    const deleteAllSessions = async () => {
        await SessionApi.deleteAllSessions();
        history.push(ROUTES.LOGIN.ROOT);
    };

    const sessionsList = sessions
        ? sessions.map((session, index) => <SessionEvent session={session} deleteSession={deleteSession} key={index} />)
        : undefined;

    return (
        <div className="session-list">
            <ContentHeader heading={t('sessions.title')} returnTo={ROUTES.PROFILE.ROOT}>
                {sessionsList && (
                    <Button
                        className="margin-left-auto"
                        leadingIcon
                        styleType="secondary"
                        label={t('sessions.delete-all')}
                        isDisabled={isLoading}
                        onClick={() => deleteAllSessions()}
                    >
                        <JamfIcon name="edit" width="24" height="24" />
                    </Button>
                )}
            </ContentHeader>
            {sessionsList}
            {isLoading && (
                <div className="spinner-middle">
                    {' '}
                    <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                </div>
            )}
        </div>
    );
};

export default Sessions;
