import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuditApi from '../../api/AuditApi';
import ProfileApi from '../../api/ProfileApi';
import SessionsApi from '../../api/SessionsApi';
import ROUTES from '../../utilities/routes';
import Content from '../Content/Content';
import Password from './Password/Password';
import SessionsOverview from './Sessions/Sessions';
import AccountHistory from '../Profile/AccountHistory/AccountHistory';
import { AuditResponse, SessionResponse } from '../../api/models/ApiResponse.model';
import Name from '../Profile/Name/Name';
import { Session, Environment, Errors, RedirectData } from '../../api/models/Data.model';
import Profile from './Profile';
import BackupCodes from './BackupCodes/BackupCodes';
import TwoFactorApp from './TwoFactorApp/TwoFactorApp';
import TwoFactorPhone from './TwoFactorPhone/TwoFactorPhone';
import { ApiError } from '../../api/ApiError';

export interface TwoFactorSettings {
    backupCodesStatus: boolean;
    backupCodesRemaining: number;
    authenticatorStatus: boolean;
    isLoading: boolean;
}

export interface PhoneSettings {
    phoneStatus: boolean;
    phoneNumber: string;
    isLoading: boolean;
}

export interface SessionsSettings {
    sessions: Session[];
    isLoading: boolean;
}

export interface AccountHistorySettings extends AuditResponse {
    isLoading: boolean;
}

export interface EnvironmentSettings {
    isLoading: boolean;
    environments: Environment[];
    environmentErrors: Errors[];
    error: string;
    redirectData: RedirectData;
}

export interface InvitationSettings {
    invitationCount: number;
}

const ProfileSecurityContainer: FC = (): ReactElement => {
    const [twoFactorSettings, setTwoFactorSettings] = useState<TwoFactorSettings>({
        backupCodesStatus: false,
        backupCodesRemaining: 0,
        authenticatorStatus: false,
        isLoading: true,
    });

    const [phoneSettings, setPhoneSettings] = useState<PhoneSettings>({
        phoneStatus: false,
        phoneNumber: '',
        isLoading: true,
    });

    const [accountHistorySettings, setAccountHistorySettings] = useState<AccountHistorySettings>({
        currentPage: 1,
        data: [],
        firstPageUrl: '',
        from: 0,
        lastPage: 0,
        lastPageUrl: '',
        nextPageUrl: '',
        path: '',
        perPage: 0,
        prevPageUrl: '',
        to: 0,
        total: 0,
        isLoading: true,
    });

    const [sessionsSettings, setSessionsSettings] = useState<SessionsSettings>({
        sessions: [],
        isLoading: true,
    });

    const [environmentsettings, setEnvironmentSettings] = useState<EnvironmentSettings>({
        environments: [],
        environmentErrors: [],
        isLoading: true,
        error: '',
        redirectData: {},
    });

    const [invitationsettings, setInvitationSettings] = useState<InvitationSettings>({
        invitationCount: 0,
    });

    const get2FASettings = async (): Promise<void> => {
        const twoFactorSettings = await ProfileApi.get2FASettings();

        setTwoFactorSettings({
            authenticatorStatus: twoFactorSettings.enabled,
            backupCodesStatus: twoFactorSettings.backupCodesEnabled,
            backupCodesRemaining: twoFactorSettings.backupCodesRemaining,
            isLoading: false,
        });
    };

    const getPhoneSettings = async (): Promise<void> => {
        const phoneSettings = await ProfileApi.getPhoneSettings();

        setPhoneSettings({
            phoneStatus: phoneSettings.enabled,
            phoneNumber: phoneSettings.number,
            isLoading: false,
        });
    };

    const getSessions = async (): Promise<void> => {
        const result: SessionResponse = await SessionsApi.getSessions();

        setSessionsSettings({ sessions: result.data, isLoading: false });
    };

    const getAudits = async (): Promise<void> => {
        const result: AuditResponse = await AuditApi.getAudits();

        setAccountHistorySettings({ ...result, isLoading: false });
    };

    const getEnvironmentSettings = async (): Promise<void> => {
        try {
            const result = await ProfileApi.getEnvironments();
            setEnvironmentSettings({
                environments: result.environments,
                environmentErrors: result.errors,
                isLoading: false,
                error: '',
                redirectData: result.redirectData,
            });
        } catch (error) {
            if (error instanceof ApiError) {
                setEnvironmentSettings({
                    environments: [],
                    environmentErrors: [],
                    isLoading: false,
                    error: error.message.error,
                    redirectData: {},
                });
            }
        }
    };

    const getInvitationSettings = async (): Promise<void> => {
        try {
            const result = await ProfileApi.getInvitations();
            setInvitationSettings({
                invitationCount: result.invitations.length,
            });
        } catch (error) {
            setInvitationSettings({
                invitationCount: 0,
            });
        }
    };

    useEffect(() => {
        getSessions();
        getAudits();
        get2FASettings();
        getPhoneSettings();
        getEnvironmentSettings();
        getInvitationSettings();
    }, []);

    const renderProfile = (): ReactElement => {
        return (
            <Profile
                twoFactorSettings={twoFactorSettings}
                phoneSettings={phoneSettings}
                sessionsSettings={sessionsSettings}
                accountHistorySettings={accountHistorySettings}
                environmentSettings={environmentsettings}
                invitationSettings={invitationsettings}
            />
        );
    };

    const renderTwoFactorApp = (): ReactElement => {
        return (
            <TwoFactorApp
                authenticatorStatus={twoFactorSettings.authenticatorStatus}
                get2FASettings={get2FASettings}
                isLoading={twoFactorSettings.isLoading}
            />
        );
    };

    const renderTwoFactorPhone = (): ReactElement => {
        return <TwoFactorPhone {...phoneSettings} getPhoneSettings={getPhoneSettings} />;
    };

    const renderBackupCodes = (): ReactElement => {
        return <BackupCodes {...twoFactorSettings} get2FASettings={get2FASettings} />;
    };

    const renderSessions = (): ReactElement => {
        return <SessionsOverview sessionsSettings={sessionsSettings} getSessions={getSessions} />;
    };

    const renderAccountHistory = (): ReactElement => {
        return (
            <AccountHistory
                setAccountHistorySetttings={setAccountHistorySettings}
                accountHistorySettings={accountHistorySettings}
            />
        );
    };

    return (
        <Content>
            <Switch>
                <Route exact path={ROUTES.PROFILE.ROOT} component={renderProfile} />
                <Route exact path={ROUTES.PROFILE.NAME} component={Name} />
                <Route exact path={ROUTES.PROFILE.PASSWORD} component={Password} />
                <Route exact path={ROUTES.PROFILE.TWOFACTOR_APP} component={renderTwoFactorApp} />
                <Route exact path={ROUTES.PROFILE.TWOFACTOR_PHONE} component={renderTwoFactorPhone} />
                <Route exact path={ROUTES.PROFILE.TWOFACTOR_BACKUP_CODES} component={renderBackupCodes} />
                <Route exact path={ROUTES.PROFILE.SESSIONS} component={renderSessions} />
                <Route exact path={ROUTES.PROFILE.ACCOUNT_HISTORY} component={renderAccountHistory} />
            </Switch>
        </Content>
    );
};

export default ProfileSecurityContainer;
