import React, { FC } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import ROUTES from '../../utilities/routes';
import AuthenticationApi from '../../api/AuthenticationApi';
import { Button, Image, JamfIcon } from '@jamf/design-system-react';
import logoColor from '../../../img/jamf-school-logo-color.svg';
import UserAvatar from './UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';

const NavBar: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const logout = async (): Promise<void> => {
        await AuthenticationApi.logout();
        history.push(ROUTES.LOGIN.ROOT);
    };

    return (
        <nav className="navbar-container" aria-label="Main">
            <header>
                <Image altText="Jamf School Logo" src={logoColor} height="36px" />
            </header>

            <div className="user-avatar-container">
                <NavLink to={ROUTES.PROFILE.ROOT}>
                    <UserAvatar />
                </NavLink>
            </div>

            <Button
                className="logout-button-large"
                leadingIcon
                styleType="ghost"
                label={t('logout.cta')}
                onClick={logout}
            >
                <JamfIcon name="logout" width="24" height="24" />
            </Button>
            <Button className="with-icon-only logout-button-small" leadingIcon styleType="ghost" onClick={logout}>
                <JamfIcon name="logout" width="24" height="24" />
            </Button>
        </nav>
    );
};

export default NavBar;
