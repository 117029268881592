import React, { FC, ReactElement, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import ROUTES from '../../../utilities/routes';
import ContentHeader from '../../Content/ContentHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, TextInput, Button } from '@jamf/design-system-react';

const Name: FC = (): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    const { user, onProfileChange, onProfileSubmit } = useContext(AuthContext);
    const { firstName, lastName } = user;

    return (
        <div>
            <ContentHeader heading={t('global.name')} returnTo={ROUTES.PROFILE.ROOT} />
            <Card className="card-wide">
                <CardBody>
                    <TextInput
                        id="first-name"
                        className="margin-bottom width"
                        type="text"
                        label={t('profile.first-name')}
                        value={firstName}
                        onChange={(firstName: string): void => onProfileChange(firstName, 'firstName')}
                    />
                    <TextInput
                        id="last-name"
                        className="margin-bottom width"
                        type="text"
                        label={t('profile.last-name')}
                        value={lastName}
                        onChange={(lastName): void => onProfileChange(lastName, 'lastName')}
                    />
                    <div className="card-buttons">
                        <Button
                            styleType={'secondary'}
                            onClick={(): void => history.push(ROUTES.PROFILE.ROOT)}
                            label={t('global.cancel')}
                        />
                        <Button
                            onClick={onProfileSubmit}
                            isDisabled={!firstName || !lastName}
                            label={t('global.save')}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default Name;
