import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { useTranslation } from 'react-i18next';
import { CardHeader, Button, CardTitle, CardBody, JamfIcon } from '@jamf/design-system-react';

const ConfirmPasswordRequest: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const goBack = (): void => {
        history.push(`${ROUTES.LOGIN.ROOT}`);
    };

    return (
        <AuthCard>
            <CardHeader className="card-header justify-flex-start">
                <Button leadingIcon styleType="secondary" className="back-button" onClick={goBack}>
                    <JamfIcon name="arrow" width="24" height="24" />
                </Button>
                <CardTitle className="card-title">{t('request-password.title')}</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
                <div className="align-items-center">
                    <JamfIcon name="checkCircle" width={18} className="margin-right-half text-success"></JamfIcon>
                    <span>{t('request-password.confirm.title')}</span>
                </div>
                <p>{t('request-password.confirm.description')}</p>
                <div className="input-row justify-flex-end">
                    <Button
                        isSubmit={true}
                        onClick={goBack}
                        label={t('request-password.confirm.cta')}
                        className="margin-top-half"
                    ></Button>
                </div>
            </CardBody>
        </AuthCard>
    );
};

export default ConfirmPasswordRequest;
