import React, { FC, MutableRefObject, useEffect, useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ProfileApi from '../../../api/ProfileApi';
import { Button, Card, CardBody, Image, SpinnerIcon, TextInput } from '@jamf/design-system-react';
import classNames from 'classnames';
import { ApiError } from '../../../api/ApiError';

interface TwoFactorSettings {
    get2FASettings: Function;
}

const TwoFactorAppEnable: FC<TwoFactorSettings> = ({ get2FASettings }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        code: '',
        error: '',
        isLoading: true,
        isEnabling: false,
        qrCode: '',
        secret: '',
    });

    const codeInput: MutableRefObject<any> = useRef();

    const refreshData = async (): Promise<void> => {
        const { secret, qrCode } = await ProfileApi.get2FASetup();

        setState((prevState) => ({
            ...prevState,
            secret: secret,
            qrCode: qrCode,
            isLoading: false,
        }));
    };

    const autoFocus = (): void => {
        if (codeInput.current) {
            codeInput.current.focus();
        }
    };

    const verify2FA = async (): Promise<void> => {
        const { code, secret } = state;

        if (!code) {
            autoFocus();
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isEnabling: true,
        }));

        try {
            await ProfileApi.enable2FA({ code, secret });
            await get2FASettings();
        } catch (error: any) {
            if (error instanceof ApiError) {
                setState((prevState) => ({
                    ...prevState,
                    error: error.message.error,
                    code: '',
                }));
                autoFocus();
            }
        }

        setState((prevState) => ({
            ...prevState,
            isEnabling: false,
        }));
    };

    const handleSubmit = (event: Event): void => {
        event.preventDefault();
        verify2FA();
    };

    useEffect(() => {
        refreshData();
    }, []);

    const { code, error, qrCode, isEnabling } = state;

    return (
        <Card className="card-wide">
            <CardBody>
                <form>
                    <h4 className="margin-bottom-half">{t('2fa.app.enable.title')}</h4>
                    <span>{t('2fa.app.description')}</span>
                    <ol>
                        <li>{t('2fa.app.enable.guide.step-one')}</li>
                        <li>{t('2fa.app.enable.guide.step-two')}</li>
                        <li>
                            <Trans i18nKey={'2fa.app.enable.guide.step-three'}></Trans>
                        </li>
                    </ol>
                    <div className="qr-code">
                        {qrCode ? (
                            <Image src={qrCode} altText={t('2fa.app.code.label')} />
                        ) : (
                            <div className="spinner-middle">
                                <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                            </div>
                        )}
                    </div>
                    <TextInput
                        leadingIcon="lock"
                        className={classNames('margin-top width', { 'margin-bottom-half': !error })}
                        label={t('2fa.app.code.label')}
                        value={code}
                        hasError={!!error}
                        descenderText={t('errors.authenticator-verification-invalid')}
                        onChange={(code) => setState({ ...state, code, error: '' })}
                        forwardRef={codeInput}
                    />
                    <div className="card-buttons">
                        <Button
                            isSubmit={true}
                            /*@ts-ignore*/
                            onClick={handleSubmit}
                            isDisabled={isEnabling}
                            isLoading={isEnabling}
                            label={t('global.enable')}
                        />
                    </div>
                </form>
            </CardBody>
        </Card>
    );
};

export default TwoFactorAppEnable;
