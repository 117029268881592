import { Api } from './Api';
import { SessionResponse, Response } from './models/ApiResponse.model';

class SessionApi extends Api {
    getSessions(): Promise<SessionResponse> {
        return this.get(`/sessions`);
    }

    deleteSession(id: number): Promise<Response> {
        return this.delete(`/sessions/${id}`);
    }

    deleteAllSessions(): Promise<Response> {
        return this.delete(`/sessions`);
    }
}

export default new SessionApi();
