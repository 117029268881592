import React from 'react';
import { User } from '../api/models/Data.model';

export interface AuthContextTypes {
    user: User;
    isAuthenticated: boolean;
    getProfile: () => Promise<User | null>;
    onProfileChange: <T extends User, K extends keyof User>(userProperty: T[K], fieldName: K) => void;
    onProfileSubmit: (event: any) => void;
}

export const AuthContext = React.createContext({} as AuthContextTypes);
