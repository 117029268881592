import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { Trans, useTranslation } from 'react-i18next';
import { CardHeader, Button, CardTitle, CardBody, JamfIcon } from '@jamf/design-system-react';
import { EnvContext } from '../../context/EnvContext';

const ResendVerificationEmailConfirm: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { supportEmail } = useContext(EnvContext);

    const goBack = (): void => {
        history.push(`${ROUTES.LOGIN.ROOT}`);
    };

    return (
        <AuthCard>
            <CardHeader className="card-header justify-flex-start">
                <Button leadingIcon styleType="secondary" className="back-button" onClick={goBack}>
                    <JamfIcon name="arrow" width="24" height="24" />
                </Button>
                <CardTitle className="card-title">{t('resend-verification-email.title')}</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
                <div className="align-items-center">
                    <JamfIcon name="checkCircle" width={18} className="margin-right-half text-success"></JamfIcon>
                    <span>{t('resend-verification-email.confirm.title')}</span>
                </div>
                <p>
                    <Trans i18nKey="resend-verification-email.confirm.description">{{ supportEmail }}</Trans>
                </p>
                <div className="input-row justify-flex-end">
                    <Button
                        isSubmit={true}
                        onClick={goBack}
                        label={t('resend-verification-email.confirm.cta')}
                        className="margin-top-half"
                    ></Button>
                </div>
            </CardBody>
        </AuthCard>
    );
};

export default ResendVerificationEmailConfirm;
