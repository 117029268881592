import React, { Component, ReactElement } from 'react';
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import ProfileApi from '../api/ProfileApi';
import { AuthContext } from '../context/AuthContext';
import ROUTES from '../utilities/routes';
import Login from './Authentication/Login';
import LoginClientList from './Authentication/LoginClientList';
import LoginClientInvitations from './Authentication/LoginClientInvitations';
import LoginTwoFactor from './Authentication/LoginTwoFactor';
import RegisterVerify from './Authentication/RegisterVerify';
import RequestPassword from './Authentication/RequestPassword';
import RequestPasswordConfirm from './Authentication/RequestPasswordConfirm';
import ResetPassword from './Authentication/ResetPassword';
import ResetPasswordConfirm from './Authentication/ResetPasswordConfirm';
import AuthRoute from './AuthRoute';
import ProfileContainer from './Profile/ProfileContainer';
import ResendVerificationEmail from './Authentication/ResendVerificationEmail';
import ResendVerificationEmailConfirm from './Authentication/ResendVerificationEmailConfirm';
import { User } from '../api/models/Data.model';
import AuthenticatorSetup from './Authentication/AuthenticatorSetup';
import { EnvContext } from '../context/EnvContext';
import ENV from '../utilities/env';
// Moment languages
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/es';

interface AppState {
    isAuthenticated: boolean;
    isLoading: boolean;
    user: User;
}

class App extends Component<RouteComponentProps> {
    state: AppState = {
        isAuthenticated: false,
        isLoading: true,
        user: {},
    };

    componentDidMount(): void {
        this.fetchData();
    }

    componentDidUpdate(prevProps: RouteComponentProps, prevState: AppState): void {
        const { user } = this.state;

        if (prevState.user !== user) {
            this.setState({
                isAuthenticated: user !== null,
            });
        }
    }

    async fetchData(): Promise<void> {
        await this.getCSS();

        await this.getProfile();

        this.setState({
            isLoading: false,
        });
    }

    async getCSS(): Promise<void> {
        await require('../../scss/Index.scss');
    }

    getProfile = async (): Promise<User | null> => {
        let user = null;

        try {
            user = await ProfileApi.getProfile();
        } catch (error) {
            // TODO handle this error
        }

        this.setState({ user });

        return user;
    };

    onProfileChange = <T extends User, K extends keyof User>(userProperty: T[K], fieldName: K): void => {
        const user: User = this.state.user;

        if (fieldName) {
            user[fieldName] = userProperty;
            this.setState({ user });
        }
    };

    onProfileSubmit = async (event: any): Promise<void> => {
        const { firstName, lastName } = this.state.user;
        const { history } = this.props;

        event.preventDefault();

        // Do nothing if first name or last name is not set
        if (!firstName || !lastName) {
            return;
        }

        // Update the profile
        await ProfileApi.setProfile({ firstName, lastName });

        // Then get the new profile
        await this.getProfile();

        history.push(ROUTES.PROFILE.ROOT);
    };

    render(): ReactElement | null {
        const { getProfile, onProfileChange, onProfileSubmit, state } = this;
        const { isAuthenticated, isLoading, user } = state;

        if (isLoading) {
            return null;
        }

        return (
            <AuthContext.Provider value={{ isAuthenticated, getProfile, user, onProfileChange, onProfileSubmit }}>
                <EnvContext.Provider value={ENV}>
                    <Switch>
                        <Route exact path={ROUTES.LOGIN.ROOT} component={Login} />
                        <Route exact path={ROUTES.LOGIN.VERIFY} component={LoginTwoFactor} />
                        <Route exact path={ROUTES.REGISTER.VERIFY} component={RegisterVerify} />
                        <Route exact path={ROUTES.REQUESTPASSWORD.ROOT} component={RequestPassword} />
                        <Route exact path={ROUTES.REQUESTPASSWORD.CONFIRM} component={RequestPasswordConfirm} />
                        <Route exact path={ROUTES.RESETPASSWORD.ROOT} component={ResetPassword} />
                        <Route exact path={ROUTES.RESETPASSWORD.CONFIRM} component={ResetPasswordConfirm} />
                        <Route exact path={ROUTES.RESENDINVITATIONEMAIL.ROOT} component={ResendVerificationEmail} />
                        <Route
                            exact
                            path={ROUTES.RESENDINVITATIONEMAIL.CONFIRM}
                            component={ResendVerificationEmailConfirm}
                        />
                        <AuthRoute exact path={ROUTES.LOGIN.SETUP_TWOFACTOR_APP} component={AuthenticatorSetup} />
                        <AuthRoute exact path={ROUTES.LOGIN.CLIENT_LIST} component={LoginClientList} />
                        <AuthRoute exact path={ROUTES.LOGIN.CLIENT_INVITATIONS} component={LoginClientInvitations} />
                        <AuthRoute path={ROUTES.PROFILE.ROOT} component={ProfileContainer} />
                        <Redirect to={ROUTES.PROFILE.ROOT} />
                    </Switch>
                </EnvContext.Provider>
            </AuthContext.Provider>
        );
    }
}

export default withRouter(App);
