interface Status {
    t: string;
    color: 'info' | 'success' | 'warning' | 'danger';
    percent: number;
}

const PASSWORD_STATUS: Status[] = [
    { t: 'short', color: 'danger', percent: 10 },
    { t: 'poor', color: 'danger', percent: 30 },
    { t: 'fair', color: 'warning', percent: 60 },
    { t: 'good', color: 'info', percent: 80 },
    { t: 'excellent', color: 'success', percent: 100 },
];

export default PASSWORD_STATUS;
