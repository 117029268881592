import queryString from 'query-string';
import React, { FC, ReactElement, useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AuthenticationApi from '../../api/AuthenticationApi';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { CardBody, CardHeader, CardTitle, TextInput, Button, Checkbox, JamfIcon } from '@jamf/design-system-react';
// import { Authorization } from '../../api/models/ApiTypes';
import { LoginResponse } from '../../api/models/ApiResponse.model';
import { ApiError } from '../../api/ApiError';
import { AuthContext } from '../../context/AuthContext';
import Register from './Register';
import ProfileApi from '../../api/ProfileApi';
import classNames from 'classnames';

enum ErrorType {
    APP = 'app',
    PHONE = 'phone',
    BACKUPCODES = 'backupcodes',
    PASSWORDEXPIRED = 'password-expired',
    ERROR = 'error',
}

const Login: FC = (): ReactElement => {
    // State
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [invited, setInvited] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [alreadyHaveAnAccount, setAlreadyHaveAnAccount] = useState(hasAccount);

    // Context
    const context = useContext(AuthContext);

    // Routing
    const location = useLocation();
    const history = useHistory();

    // Translate
    const { t } = useTranslation();

    // Refs
    const emailInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const rememberInput = useRef<HTMLInputElement>(null);

    const autoFocus = useCallback((): void => {
        if (emailInput.current && passwordInput.current) {
            // If both have values do not focus
            if (emailInput.current.value && passwordInput.current.value) {
                return;
            }
            !emailInput.current.value ? emailInput.current.focus() : passwordInput.current.focus();
        }
    }, []);

    const onEmailUpdate = (email: string) => {
        setEmail(email);
        setError('');
    };

    const onPasswordUpdate = (password: string) => {
        setPassword(password);
        setError('');
    };

    const getErrorType = (errorMessage: string): ErrorType => {
        switch (errorMessage) {
            case 'authenticator-code-needed':
                return ErrorType.APP;
            case 'mobile-code-needed':
                return ErrorType.PHONE;
            case 'backup-code-needed':
                return ErrorType.BACKUPCODES;
            case 'password-expired':
                return ErrorType.PASSWORDEXPIRED;
            default:
                return ErrorType.ERROR;
        }
    };

    useEffect((): void => {
        // Store the clientId in the Local Storage
        if (location.search) {
            const { clientId, invite, error, email, hasAccount } = queryString.parse(location.search);

            if (clientId && typeof clientId === 'string') {
                localStorage.setItem('clientId', clientId);
            }

            if (invite) {
                setInvited(invite === '1');
            }

            if (error && typeof error === 'string') {
                setError(error);
            }

            if (email && typeof email === 'string') {
                setEmail(email);
            }

            if (hasAccount && hasAccount === '1') {
                setHasAccount(true);
                setAlreadyHaveAnAccount(true);
            }
        }

        // Autofocus on the correct input
        autoFocus();
    }, [location.search, autoFocus]);

    const handleSuccess = useCallback(
        async (response: LoginResponse): Promise<void> => {
            // Show the 2FA setup for new users
            if (response.data.show2faSetup === true) {
                history.push(ROUTES.LOGIN.SETUP_TWOFACTOR_APP);

                return;
            }

            // Check if we need to refer the user to a client
            const clientId = localStorage.getItem('clientId');
            let url = `${process.env.REACT_APP_ACCOUNT_URL}/flow/authorize-complete`;

            if (clientId) {
                // Remove the Client ID from the Local Storage
                localStorage.removeItem('clientId');

                // Refer the user to the client
                url += `?clientId=${clientId}`;
            }

            window.location.href = url;
            return;
        },
        [history]
    );

    const handleFailure = useCallback(
        async (error: ApiError): Promise<void> => {
            setError('');
            const type = getErrorType(error.message.error);

            // Push to the route for 2FA
            if (type === ErrorType.APP || type === ErrorType.PHONE || type === ErrorType.BACKUPCODES) {
                const { hasBackupCodesEnabled, hasPhoneAuth, phoneNumber } = error.message;
                history.push({
                    pathname: ROUTES.LOGIN.VERIFY,
                    state: {
                        email,
                        password,
                        remember,
                        type,
                        phoneNumber,
                        hasPhoneAuth,
                        hasBackupCodesAuth: hasBackupCodesEnabled,
                        hasAppAuth: type === ErrorType.APP,
                    },
                });

                return;
            } else if (type === ErrorType.PASSWORDEXPIRED) {
                const { email, t } = error.message;
                history.push({
                    pathname: ROUTES.RESETPASSWORD.ROOT,
                    state: {
                        email: email,
                        token: t,
                        alert: 'password-expired',
                    },
                });
                return;
            }

            // Focus might be off, so re-focus
            autoFocus();

            // Set the error state and reset the password
            setError(error.message.error);
            setIsLoading(false);
            setPassword('');
        },
        [autoFocus, email, history, password, remember]
    );

    const hasError =
        error === 'invalid-credentials' ||
        error === 'too-many-login-attempts' ||
        error === 'alt-auth-failed' ||
        error === 'user-not-verified';

    /**
     * Handle form submit
     */
    const handleSubmit = useCallback(
        async (event?: Event): Promise<void> => {
            event && event.preventDefault();
            setEmailError('');

            // Do nothing if the email or password is blank. If so, focus on the blank one.
            if (!email || !password) {
                autoFocus();

                return;
            }

            setIsLoading(true);

            // Check if we're in the invite workflow & verify email
            if (invited) {
                const invitationResponse = await ProfileApi.getInvitationEmail().catch(() => undefined);
                if (!invitationResponse || invitationResponse.error || invitationResponse.email !== email) {
                    setEmailError('no-session-for-invite');
                    setIsLoading(false);
                    return;
                }
            }

            try {
                const response = await AuthenticationApi.login({ email, password, remember, invited });

                // Authenticate
                await context.getProfile();

                handleSuccess(response);
            } catch (error) {
                if (error instanceof ApiError) {
                    handleFailure(error);
                }
            }
        },
        [autoFocus, context, email, handleFailure, handleSuccess, password, remember, invited]
    );

    // /**
    //  * Handle redirection when logging in via third party
    //  */
    // const handleRedirect = useCallback(
    //     async (type: Authorization): Promise<void> => {
    //         try {
    //             // Trying to get the redirect url
    //             const response = await AuthenticationApi.getAuthorizationUrl(type);
    //
    //             // Redirecting to googles login page
    //             window.location.href = response.redirectUrl;
    //         } catch (error) {
    //             handleFailure(error);
    //         }
    //     },
    //     [handleFailure]
    // );

    const setAccountReady = () => {
        setAlreadyHaveAnAccount(true);
    };

    return (
        <AuthCard>
            <CardHeader className="card-header">
                <CardTitle className="card-title">
                    {invited && !alreadyHaveAnAccount
                        ? t('login.invited')
                        : invited && alreadyHaveAnAccount
                        ? t('login.join-new-school')
                        : t('global.sign-in')}
                </CardTitle>
            </CardHeader>
            <div className="login-container">
                {invited && !alreadyHaveAnAccount ? (
                    <CardBody className="card-body">
                        <div className="register">
                            <Register alreadyHaveAnAccount={setAccountReady} email={email}></Register>
                        </div>
                    </CardBody>
                ) : (
                    <CardBody className="card-body login">
                        <form>
                            {alreadyHaveAnAccount ? (
                                <>
                                    <p>{t('login.invited-join-description')}</p>
                                    <div className={classNames('text-label margin-bottom-half', { error: emailError })}>
                                        {t('global.emailaddress')}
                                    </div>
                                    <div className="margin-bottom-large">
                                        <div className="register-email-address">
                                            <JamfIcon name="envelope" className="margin-right" height={16} width={16} />
                                            <span>{email}</span>
                                        </div>
                                        {emailError && (
                                            <div className="descender-container margin-bottom-large">
                                                <span className="error-text">{t(`errors.${emailError}`)}</span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <TextInput
                                    label={t('global.emailaddress')}
                                    leadingIcon="envelope"
                                    id="email"
                                    value={email}
                                    hasError={hasError}
                                    className="margin-bottom width"
                                    onChange={(value): void => onEmailUpdate(value)}
                                    forwardRef={emailInput}
                                ></TextInput>
                            )}
                            <TextInput
                                label={t('global.password')}
                                leadingIcon="lock"
                                id="password"
                                type="password"
                                value={password}
                                hasError={hasError}
                                descenderText={t(`errors.${error}`)}
                                className="margin-bottom width"
                                onChange={(password) => onPasswordUpdate(password)}
                                forwardRef={passwordInput}
                            ></TextInput>
                            {hasError && error === 'user-not-verified' && (
                                <p>
                                    <Link
                                        className="link"
                                        to={{
                                            pathname: ROUTES.RESENDINVITATIONEMAIL.ROOT,
                                            state: { email },
                                            search: location.search,
                                        }}
                                    >
                                        {t('login.resend-verification-email')}
                                    </Link>
                                </p>
                            )}
                            <p>
                                <Link
                                    className="link"
                                    to={{
                                        pathname: ROUTES.REQUESTPASSWORD.ROOT,
                                        state: { email },
                                        search: location.search,
                                    }}
                                >
                                    {t('login.forgot-password')}
                                </Link>
                            </p>
                            <div className="input-row">
                                <Checkbox
                                    onChange={(checked) => setRemember(checked)}
                                    checked={remember}
                                    label={t('login.remember')}
                                    forwardRef={rememberInput}
                                ></Checkbox>
                                <Button
                                    isSubmit={true}
                                    /*@ts-ignore*/
                                    onClick={handleSubmit}
                                    isDisabled={isLoading}
                                    label={t('login.cta')}
                                    isLoading={isLoading}
                                ></Button>
                            </div>
                            {/*<div className="input-row alt-login-buttons">*/}
                            {/*    <Button*/}
                            {/*        onClick={(): Promise<void> => handleRedirect('google')}*/}
                            {/*        leadingIcon="google"*/}
                            {/*        styleType="secondary"*/}
                            {/*        label={t('login.alt.google')}*/}
                            {/*    ></Button>*/}
                            {/*    <Button*/}
                            {/*        onClick={(): Promise<void> => handleRedirect('microsoft')}*/}
                            {/*        leadingIcon="microsoft"*/}
                            {/*        styleType="secondary"*/}
                            {/*        label={t('login.alt.microsoft')}*/}
                            {/*    ></Button>*/}
                            {/*</div>*/}
                        </form>
                    </CardBody>
                )}
            </div>
        </AuthCard>
    );
};

export default Login;
