import React, { FC, useContext, useEffect, useState } from 'react';
import { Errors, Invitation } from '../../../api/models/Data.model';
import { Button, JamfIcon, TextInput } from '@jamf/design-system-react';
import { Trans, useTranslation } from 'react-i18next';
import { EnvContext } from '../../../context/EnvContext';

interface InvitationListProps {
    invitations: Invitation[];
    errors: Errors[];
}

const InvitationList: FC<InvitationListProps> = ({ invitations = [], errors = [] }) => {
    const { t } = useTranslation();
    const { supportEmail } = useContext(EnvContext);

    const [list, setList] = useState<Invitation[]>(invitations);
    const [errorList, setErrorList] = useState<Errors[]>(errors);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setList(
            invitations.filter(
                (env) =>
                    env.company.toLowerCase().includes(search.toLowerCase()) ||
                    env.url.toLowerCase().includes(search.toLowerCase())
            )
        );

        setErrorList(errors);
    }, [search, invitations, errors]);

    return (
        <>
            {invitations.length > 10 && (
                <TextInput
                    type="text"
                    name="search"
                    leadingIcon="magnifier"
                    value={search}
                    onChange={(search) => setSearch(search)}
                    className="width"
                ></TextInput>
            )}
            {errorList.length > 0 &&
                !search &&
                errorList.map((envErr, envIndex) => {
                    const name = envErr.name;

                    return (
                        <div className="profile-link-section" key={envIndex}>
                            <div className="section-content">
                                <div className="alert danger" key={envIndex}>
                                    <JamfIcon width={24} name="error" />
                                    <div className="flex-column">
                                        <span className="title">
                                            <Trans i18nKey={'list.error-title'}>{{ name }}</Trans>
                                        </span>
                                        <span>
                                            <Trans i18nKey={'list.error-hint'}>{{ supportEmail }}</Trans>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            {list.map((env, index) => {
                return (
                    <div className="profile-link-section" key={index}>
                        <div className="section-content">
                            <span className="env-list-name">{env.company}</span>
                        </div>
                        <Button
                            styleType="ghost"
                            className="link"
                            label={t('invitation-list.accept-invitation')}
                            onClick={() => (window.location.href = env.url)}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default InvitationList;
