import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../utilities/routes';
import AuthCard from './AuthCard/AuthCard';
import { useTranslation } from 'react-i18next';
import { CardHeader, Button, CardTitle, CardBody, JamfIcon } from '@jamf/design-system-react';

const ConfirmPasswordReset: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const goBack = (): void => {
        history.push(ROUTES.LOGIN.ROOT);
    };

    return (
        <AuthCard>
            <CardHeader className="card-header justify-flex-start">
                <Button leadingIcon styleType="secondary" className="back-button" onClick={goBack}>
                    <JamfIcon name="arrow" width="24" height="24" />
                </Button>
                <CardTitle className="card-title">{t('reset-password.title')}</CardTitle>
            </CardHeader>
            <CardBody className="card-body">
                <div className="align-items-center">
                    <JamfIcon name="checkCircle" width={18} className="margin-right-half text-success"></JamfIcon>
                    <span>{t('reset-password.confirm.description')}</span>
                </div>
                <div className="input-row justify-flex-end margin-top">
                    <Button label={t('reset-password.confirm.cta')} onClick={goBack}></Button>
                </div>
            </CardBody>
        </AuthCard>
    );
};

export default ConfirmPasswordReset;
