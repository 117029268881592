import React, { FC } from 'react';
import NavBar from '../NavBar/NavBar';

const Content: FC = ({ children }) => (
    <>
        <NavBar />
        <div className="main-content">
            <div className="page-container">{children}</div>
        </div>
    </>
);

export default Content;
