import React, { FC } from 'react';
import { Card, Image } from '@jamf/design-system-react';
import classNames from 'classnames';
import logo from '../../../../img/jamf-school-logo.svg';

const AuthCard: FC<{ wide?: boolean }> = ({ children, wide = false }) => {
    return (
        <div className="bg-image">
            <div className="auth-container">
                <Image className="main-logo" altText="Jamf School logo" src={logo}></Image>
                <Card className={classNames('auth-card', { wide })}>{children}</Card>
            </div>
        </div>
    );
};

export default AuthCard;
