import React, { FC, ReactElement, useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ROUTES from '../../utilities/routes';
import ProfileImage from './ProfileImage/ProfileImage';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Card, CardHeader, CardBody, CardTitle, SpinnerIcon, JamfIcon } from '@jamf/design-system-react';
import { useHistory } from 'react-router-dom';
import ProfileSection from './ProfileSection';
import {
    TwoFactorSettings,
    PhoneSettings,
    SessionsSettings,
    AccountHistorySettings,
    EnvironmentSettings,
    InvitationSettings,
} from './ProfileContainer';
import i18next from 'i18next';
import moment from 'moment';
import EnvironmentList from '../Authentication/EnvironmentList/EnvironmentList';
import classNames from 'classnames';
import { EnvContext } from '../../context/EnvContext';

interface ProfileProps {
    twoFactorSettings: TwoFactorSettings;
    phoneSettings: PhoneSettings;
    sessionsSettings: SessionsSettings;
    accountHistorySettings: AccountHistorySettings;
    environmentSettings: EnvironmentSettings;
    invitationSettings: InvitationSettings;
}

const Profile: FC<ProfileProps> = ({
    twoFactorSettings,
    phoneSettings,
    sessionsSettings,
    accountHistorySettings,
    environmentSettings,
    invitationSettings,
}): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    const { supportEmail } = useContext(EnvContext);
    const { user, getProfile } = useContext(AuthContext);
    const [toggleAccounts, setToggleAccounts] = useState(true);

    const timeStamp = accountHistorySettings.data[0] ? accountHistorySettings.data[0].timestamp : '';
    const lastEventDate = moment(timeStamp).locale(i18next.language).fromNow();
    const activeSessions = sessionsSettings.sessions.length;
    const invitationButtonText =
        t('list.invitation-button') +
        (invitationSettings.invitationCount > 0 ? ' (' + invitationSettings.invitationCount + ')' : '');

    return (
        <div className="profile-page">
            <div className="profile-display-details">
                <ProfileImage getProfile={getProfile} avatar={user.avatar} fullName={user.fullName} />

                <div className="name">{user.fullName}</div>
                <div className="email">{user.email}</div>
            </div>
            <div className="profile-buttons">
                <Button
                    styleType={'secondary'}
                    label={invitationButtonText}
                    leadingIcon
                    onClick={(): void => history.push(ROUTES.LOGIN.CLIENT_INVITATIONS)}
                >
                    <JamfIcon name="inbox" width="24" height="24" />
                </Button>
                <Button
                    styleType={'secondary'}
                    label={t('profile.edit-profile-name')}
                    leadingIcon
                    onClick={(): void => history.push(ROUTES.PROFILE.NAME)}
                >
                    <JamfIcon name="edit" width="24" height="24" />
                </Button>
                <Button
                    styleType={'secondary'}
                    label={t('profile.change-password')}
                    leadingIcon
                    onClick={(): void => history.push(ROUTES.PROFILE.PASSWORD)}
                >
                    <JamfIcon name="lock" width="24" height="24" />
                </Button>
            </div>

            <div className="profile-cards">
                <Card className="card-wide margin-bottom">
                    <CardHeader className="card-header-overview">
                        <CardTitle className="card-title">{t('client-list.title')}</CardTitle>
                        <Button
                            styleType="ghost"
                            leadingIcon
                            className={classNames('toggle-button', { hide: !toggleAccounts, show: toggleAccounts })}
                            onClick={() => setToggleAccounts(!toggleAccounts)}
                        >
                            <JamfIcon name="chevron" width="24" height="24" />
                        </Button>
                    </CardHeader>
                    <CardBody className={classNames('card-body', { hidden: !toggleAccounts })}>
                        {environmentSettings.isLoading && (
                            <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                        )}
                        {!environmentSettings.isLoading && environmentSettings.error && (
                            <div className="alert danger">
                                <JamfIcon width={24} name="error" />
                                <div className="flex-column">
                                    <span className="title">{t('list.error-title-generic')}</span>
                                    <span>
                                        <Trans i18nKey={'list.error-hint'}>{{ supportEmail }}</Trans>
                                    </span>
                                </div>
                            </div>
                        )}
                        {!environmentSettings.isLoading &&
                        (environmentSettings.environments.length >= 0 ||
                            environmentSettings.environmentErrors.length > 0) ? (
                            <EnvironmentList
                                environments={environmentSettings.environments}
                                errors={environmentSettings.environmentErrors}
                                redirectData={environmentSettings.redirectData}
                            ></EnvironmentList>
                        ) : (
                            environmentSettings.environmentErrors.length === 0 &&
                            environmentSettings.environments.length === 0 &&
                            !environmentSettings.error && (
                                <div className="flex-column">
                                    <span>{t('client-list.no-accounts')}</span>
                                </div>
                            )
                        )}
                    </CardBody>
                </Card>

                <Card className="card-wide">
                    <CardHeader className="card-header-overview">
                        <CardTitle className="card-title">{t('security.title')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <ProfileSection
                            sectionTitle={t('profile-security-overview.app-two-factor')}
                            settingStatus={twoFactorSettings.authenticatorStatus}
                            to={ROUTES.PROFILE.TWOFACTOR_APP}
                            isLoading={twoFactorSettings.isLoading}
                        ></ProfileSection>
                        <ProfileSection
                            sectionTitle={t('profile-security-overview.sms-two-factor')}
                            settingStatus={phoneSettings.phoneStatus}
                            to={ROUTES.PROFILE.TWOFACTOR_PHONE}
                            isLoading={phoneSettings.isLoading}
                        ></ProfileSection>
                        <ProfileSection
                            sectionTitle={t('profile-security-overview.backup-codes')}
                            settingStatus={twoFactorSettings.backupCodesStatus}
                            to={ROUTES.PROFILE.TWOFACTOR_BACKUP_CODES}
                            isLoading={twoFactorSettings.isLoading}
                        ></ProfileSection>
                        <ProfileSection
                            sectionTitle={t('profile-security-overview.sign-in-sessions')}
                            text={
                                <Trans i18nKey="profile-security-overview.active-sessions">{{ activeSessions }})</Trans>
                            }
                            to={ROUTES.PROFILE.SESSIONS}
                            isLoading={sessionsSettings.isLoading}
                        ></ProfileSection>
                        <ProfileSection
                            sectionTitle={t('profile-security-overview.recent-event')}
                            to={ROUTES.PROFILE.ACCOUNT_HISTORY}
                            text={lastEventDate}
                            isLoading={accountHistorySettings.isLoading}
                        ></ProfileSection>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default Profile;
