import Cookies from 'universal-cookie';
import { Method, CSRFHeader } from './models/ApiTypes';
import { ApiError } from './ApiError';

export abstract class Api {
    protected get<T>(url: string, data?: {}): Promise<T> {
        return this.execute('GET', url, data);
    }

    protected post<T>(url: string, data?: {}): Promise<T> {
        return this.execute('POST', url, data);
    }

    protected put<T>(url: string, data?: {}): Promise<T> {
        return this.execute('PUT', url, data);
    }

    protected delete<T>(url: string, data?: {}): Promise<T> {
        return this.execute('DELETE', url, data);
    }

    async execute<T>(method: Method, path: string, data?: {}): Promise<T> {
        const cookies = new Cookies();

        let credentials: RequestCredentials = 'same-origin';
        let csrfHeader: CSRFHeader = 'X-XSRF-TOKEN';
        let csrfToken: string = cookies.get('XSRF-TOKEN');
        const mode: RequestMode = 'cors';

        if (process.env.NODE_ENV === 'development') {
            credentials = 'include';
            csrfHeader = 'X-CSRF-TOKEN';
            csrfToken = 'csrf-local-test-token';
        }

        // Request Options
        const options: RequestInit = {
            cache: 'no-cache',
            credentials,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                [csrfHeader]: csrfToken,
            },
            method,
            mode,
        };

        // Request URL
        const url = process.env.REACT_APP_API_URL + path;

        // Stringify data if the method is not set to GET
        if (data && method !== 'GET') {
            options.body = JSON.stringify(data);
        }

        // Ready to fetch
        try {
            const response = await fetch(url, options);
            const json = await response.json();

            if (!response.ok) {
                throw json;
            }

            return json;
        } catch (error: any) {
            throw new ApiError(error);
        }
    }
}
