import { Api } from './Api';
import { AuditResponse } from './models/ApiResponse.model';

class AuditApi extends Api {
    getAudits(): Promise<AuditResponse> {
        return this.get(`/audit-events`);
    }

    goToPage(url: string): Promise<AuditResponse> {
        return this.get(`/audit-events${url}`);
    }
}

export default new AuditApi();
