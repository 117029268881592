import React, { FC, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileApi from '../../../api/ProfileApi';
import { JamfIcon, SpinnerIcon, Card, CardBody, Button } from '@jamf/design-system-react';

interface BackupCodesEanbleProps {
    get2FASettings: () => void;
    showGenerateCodes: boolean;
    backupCodesStatus: boolean;
}

const BackupCodesEnable: FC<BackupCodesEanbleProps> = ({ get2FASettings, showGenerateCodes, backupCodesStatus }) => {
    const { t } = useTranslation();

    const [backupCodes, setBackupCodes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const getCodes = useCallback(async () => {
        setIsLoading(true);
        setSuccess(false);

        try {
            const { codes } = await ProfileApi.enableBackupCodes();
            setBackupCodes(codes);
        } catch (error) {
            return;
        }
        setSuccess(true);
        setIsLoading(false);
    }, []);

    const downloadTxtFile = () => {
        const element = document.createElement('a');
        const file = new Blob([backupCodes.join('\n')], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'jamfschool-backupcodes.txt';
        document.body.appendChild(element);
        element.click();
    };

    const copy2Clipboard = (event: any) => {
        const textField = document.createElement('textarea');
        textField.innerHTML = backupCodes.join('\n');
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        event.target.focus();
    };

    useEffect(() => {
        // get codes when showGenerateCodes changes to true, only when backup codes are already enabled
        if (showGenerateCodes && backupCodesStatus) {
            getCodes();
        }
    }, [showGenerateCodes, backupCodesStatus, getCodes]);

    return (
        <Card className="card-wide">
            <CardBody>
                <h4 className="margin-bottom-half">{t('2fa.backupcodes.title')}</h4>
                <span>{t('2fa.backupcodes.description')}</span>
                <div className="card-buttons">
                    {success && (
                        <div className="align-items-center change-indicator">
                            <JamfIcon
                                name="checkCircle"
                                width={18}
                                className="margin-right-half text-success"
                            ></JamfIcon>
                            <span>{t('2fa.backupcodes.codes-generated')}</span>
                        </div>
                    )}
                    {isLoading && (
                        <div className="align-items-center change-indicator">
                            <SpinnerIcon
                                id="spinner"
                                className="margin-right-half"
                                color={'var(--color-primary-base)'}
                            />
                            <span>{t('2fa.backupcodes.generating-codes')}</span>
                        </div>
                    )}
                    <Button
                        styleType="secondary"
                        isDisabled={isLoading}
                        onClick={getCodes}
                        label={
                            (backupCodesStatus && showGenerateCodes) || backupCodes.length > 0
                                ? t('2fa.backupcodes.re-generate')
                                : t('2fa.backupcodes.generate')
                        }
                    />
                </div>
                {backupCodes.length > 0 && !isLoading && (
                    <>
                        <p className="margin-top-large">
                            <strong>{t('2fa.backupcodes.enable.description')}</strong>
                        </p>
                        <p className="codes-area">
                            <ul className="list-unstyled" style={{ columnCount: 2 }}>
                                {backupCodes.map((code, index) => (
                                    <li key={index}>{code}</li>
                                ))}
                            </ul>
                        </p>
                        <span className="margin-top">{t('2fa.backupcodes.enable.hint')}</span>
                        <div className="card-buttons margin-top">
                            <Button styleType="secondary" onClick={copy2Clipboard} label={t('2fa.backupcodes.copy')} />
                            <Button
                                styleType="secondary"
                                onClick={downloadTxtFile}
                                label={t('2fa.backupcodes.download')}
                            />
                            <Button styleType="primary" onClick={get2FASettings} label={t('global.done')} />
                        </div>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default BackupCodesEnable;
