import React, { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, JamfIcon, SpinnerIcon } from '@jamf/design-system-react';
import { useTranslation } from 'react-i18next';

interface ListGroupProps {
    sectionTitle: string;
    settingStatus?: boolean;
    linkIcon?: string;
    text?: string | React.ReactElement;
    to: string;
    isLoading: boolean;
}

const ProfileSection: FC<ListGroupProps> = ({ sectionTitle, settingStatus, text, to, isLoading }): ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="profile-link-section">
            <div className="section-content">
                <div className="section-title">{sectionTitle}</div>
                {isLoading ? (
                    <SpinnerIcon id="spinner" color={'var(--color-primary-base)'} />
                ) : settingStatus !== undefined ? (
                    <div className="setting-section">
                        <span className={`setting-icon ${settingStatus ? 'enabled' : 'disabled'}`}></span>
                        <span className="section-text">
                            {settingStatus ? t('global.enabled') : t('global.disabled')}
                        </span>
                    </div>
                ) : (
                    <div className="setting-section-icon">
                        <JamfIcon className="calendar-icon" name="calendarDate" height={16} width={16} />
                        <span className="section-text">{text}</span>
                    </div>
                )}
            </div>
            <Button
                styleType="ghost"
                className="link"
                label={
                    settingStatus !== undefined
                        ? t('profile-security-overview.edit')
                        : t('profile-security-overview.all')
                }
                leadingIcon={settingStatus !== undefined}
                onClick={(): void => history.push(to)}
                isDisabled={isLoading}
            >
                {settingStatus !== undefined && <JamfIcon name="edit" width="24" height="24" />}
            </Button>
        </div>
    );
};

export default ProfileSection;
