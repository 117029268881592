import React, { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button, JamfIcon } from '@jamf/design-system-react';

export interface ContentHeaderProps {
    heading: string;
    returnTo: string;
    className?: string;
}

const ContentHeader: FC<ContentHeaderProps> = ({ children, className = '', heading, returnTo }): ReactElement => {
    const history = useHistory();

    return (
        <header className={classNames('main-content-header', className)}>
            <div className={'content-header-button'}>
                <Button
                    leadingIcon
                    styleType="secondary"
                    className="with-icon-only content-header-icon"
                    onClick={(): void => history.push(returnTo)}
                >
                    <JamfIcon name="arrow" width="24" height="24" />
                </Button>
            </div>
            <h1>{heading}</h1>
            {children}
        </header>
    );
};

export default ContentHeader;
