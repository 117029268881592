import { Api } from './Api';
import {
    User,
    VerifyEmail,
    DisableTwoFactor,
    EnableTwoFactor,
    Email,
    Phone,
    PhoneAuthentication,
    Avatar,
    UpdatePassword,
    Environments,
    Invitations,
} from './models/Data.model';
import {
    Response,
    TwoFactorSettingsResponse,
    TwoFactorSetupDetailsResponse,
    BackupCodesResponse,
    PhonesResponse,
    AvatarResponse,
} from './models/ApiResponse.model';

class ProfileApi extends Api {
    getProfile(): Promise<User> {
        return this.get(`/profile`);
    }

    getInvitationEmail(): Promise<Email> {
        return this.get(`/profile/invitation-email`);
    }

    getEnvironments(): Promise<Environments> {
        return this.get(`/profile/client-list`);
    }

    getInvitations(): Promise<Invitations> {
        return this.get(`/profile/client-invitations`);
    }

    createProfile(options: User): Promise<User> {
        return this.post(`/profile`, options);
    }

    verifyProfile(options: VerifyEmail): Promise<Response> {
        return this.post(`/profile/verify-email`, options);
    }

    setProfile(options: User): Promise<Response> {
        return this.put(`/profile`, options);
    }

    get2FASettings(): Promise<TwoFactorSettingsResponse> {
        return this.get(`/profile/2fa`);
    }

    get2FASetup(): Promise<TwoFactorSetupDetailsResponse> {
        return this.get(`/profile/2fa-setup`);
    }

    skip2FA(): Promise<Response> {
        return this.post(`/profile/2fa-skip`);
    }

    enable2FA(options: EnableTwoFactor): Promise<Response> {
        return this.post(`/profile/2fa`, options);
    }

    disable2FA(options: DisableTwoFactor): Promise<Response> {
        return this.delete(`/profile/2fa`, options);
    }

    enableBackupCodes(): Promise<BackupCodesResponse> {
        return this.post(`/profile/2fa-backup-codes`);
    }

    disableBackupCodes(): Promise<Response> {
        return this.delete(`/profile/2fa-backup-codes`);
    }

    generateBackupCodes(): Promise<BackupCodesResponse> {
        return this.put(`/profile/2fa-backup-codes`);
    }

    getPhoneSettings(): Promise<PhonesResponse> {
        return this.get(`/profile/phone`);
    }

    savePhoneNumber(options: Phone): Promise<Response> {
        return this.post(`/profile/phone`, options);
    }

    enablePhoneAuthentication(options: PhoneAuthentication): Promise<Response> {
        return this.post(`/profile/phone-auth`, options);
    }

    disablePhoneAuthentication(options: PhoneAuthentication): Promise<Response> {
        return this.delete(`/profile/phone-auth`, options);
    }

    requestPhoneCode(): Promise<Response> {
        return this.get(`/profile/phone-auth-send`);
    }

    uploadAvatar(options: Avatar): Promise<AvatarResponse> {
        return this.post(`/profile/avatar`, options);
    }

    changePassword(options: UpdatePassword): Promise<Response> {
        return this.put(`/profile/password`, options);
    }
}

export default new ProfileApi();
