export interface ApiErrorMessage {
    error: string;
    success: boolean;
}

export class ApiError {
    name: string;
    message: ApiErrorMessage | any;
    code: number;
    data: any;

    constructor(message: ApiErrorMessage, code: number = 0, data = []) {
        this.name = 'ApiError';
        this.message = message;
        this.code = code;
        this.data = data;
    }
}
